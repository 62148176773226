import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

export const web = [
  {
    path: "/web-about",
    name: "about",
    component: () =>
      import(/*webpackChunkName: "web-about"*/ "../views/web_about"),
    meta: {
      permissions: [
        {
          access: true,
        },
      ],
      roles: "",
    },
  },
  {
    path: "/web-contact",
    name: "contact",
    component: () =>
      import(/*webpackChunkName: "web-contact"*/ "../views/web_contact"),
    meta: {
      permissions: [
        {
          access: true,
        },
      ],
      roles: "",
    },
  },
  {
    path: "/termOfUse",
    name: "termOfUse",
    component: () =>
      import(/*webpackChunkName: "web-termOfUse"*/ "../views/web_termOfUse"),
    meta: {
      permissions: [
        {
          access: true,
        },
      ],
      roles: "",
    },
  },
  {
    path: "/download",
    name: "download",
    component: () =>
      import(/*webpackChunkName: "download"*/ "../views/web_download"),
    meta: {
      permissions: [
        {
          access: true,
        },
      ],
      roles: "",
    },
  },
  {
    path: "/my-profile",
    name: "my-profile",
    component: () =>
      import(/*webpackChunkName: "my-profile"*/ "../views/user/editProfile"),
    meta: {
      permissions: [
        {
          access: true,
        },
      ],
      roles: "",
    },
  },
  {
    path: "/add-property",
    name: "add-property",
    component: () =>
      import(/*webpackChunkName: "add-property"*/ "../views/sb_property.vue"),
    meta: {
      permissions: [
        {
          access: true,
        },
      ],
      roles: "",
    },
  },
  {
    path: "/add-payment",
    name: "add-payment-noparam",
    component: () =>
      import(/*webpackChunkName: "add-payment"*/ "../views/web_payment"),
    meta: {
      permissions: [
        {
          access: true,
        },
      ],
      roles: "",
    },
  },
  {
    path: "/upgrade-agent",
    name: "upgrade-agent",
    component: () =>
      import(/*webpackChunkName: "upgrade-agent"*/ "../views/web_upgradeAgent"),
    meta: {
      permissions: [
        {
          access: true,
        },
      ],
      roles: "",
    },
  },
  {
    path: "/add-payment/:userId/:planId",
    name: "add-payment",
    component: () =>
      import(/*webpackChunkName: "add-payment"*/ "../views/web_payment"),
    meta: {
      permissions: [
        {
          access: true,
        },
      ],
      roles: "",
    },
  },
  {
    path: "/subscribe-success",
    name: "subscribe-success",
    component: () =>
      import(
        /*webpackChunkName: "web-subscribe-success"*/ "../views/web_paymentSuccess.vue"
      ),
    meta: {
      permissions: [
        {
          access: true,
        },
      ],
      roles: "",
    },
  },
  {
    path: "/subscribe-success-mobile",
    name: "subscribe-success-mobile",
    component: () =>
      import(
        /*webpackChunkName: "web-subscribe-success-mobile"*/ "../views/web_paymentSuccessMobile.vue"
      ),
    meta: {
      permissions: [
        {
          access: true,
        },
      ],
      roles: "",
    },
  },
  // {
  //     path: "/id/:userId",
  //     name: "my-property",
  //     component: () =>
  //         import(/*webpackChunkName: "web_property"*/ "../views/web_myProperty.vue"),
  //     meta: {
  //         permissions: [

  //             {
  //                 access: true,
  //             }
  //         ],
  //         roles: "",
  //     }
  // },
  {
    path: "/sell-my-house-fast",
    name: "sell-my-house-fast",
    component: () =>
      import(/*webpackChunkName: "web_property"*/ "../views/web_property.vue"),
    meta: {
      permissions: [
        {
          access: true,
        },
      ],
      roles: "",
    },
  },
  {
    path: "/real-estate-agent/:province/:propertyId/:type" + ".html",
    name: "real-estate-agent",
    component: () =>
      import(
        /*webpackChunkName: "web_propertyDetail"*/ "../views/web_propertyDetail"
      ),
    meta: {
      permissions: [
        {
          access: true,
        },
      ],
      roles: "",
    },
  },
  {
    path: "/real-estate-agent/:type/:province/:propertyId",
    name: "real-estate-agent",
    component: () =>
      import(
        /*webpackChunkName: "web_propertyDetail"*/ "../views/web_propertyDetail"
      ),
    meta: {
      permissions: [
        {
          access: true,
        },
      ],
      roles: "",
    },
  },
  // {
  //     path: "/real-estate-agent/:type/:province/:propertyId",
  //     name: "real-estate-agent-2",
  //     component: () =>
  //         import(/*webpackChunkName: "web_propertyDetail"*/ "../views/web_propertyDetail"),
  //     meta: {
  //         permissions: [

  //             {
  //                 access: true,
  //             }
  //         ],
  //         roles: "",
  //     }
  // },

  {
    path: "/agents",
    name: "all-agent",
    component: () =>
      import(/*webpackChunkName: "all-agent"*/ "../views/web_agents"),
    meta: {
      permissions: [
        {
          access: true,
        },
      ],
      roles: "",
    },
  },

  {
    path: "/agent/:agent_id",
    name: "agent-detail",
    component: () =>
      import(/*webpackChunkName: "agent-detail"*/ "../views/web_agentDetail"),
    meta: {
      permissions: [
        {
          access: true,
        },
      ],
      roles: "",
    },
  },

  {
    path: "/news",
    name: "all-news",
    component: () => import(/*webpackChunkName: "news"*/ "../views/web_news"),
    meta: {
      permissions: [
        {
          access: true,
        },
      ],
      role: "",
    },
  },
  {
    path: "/news-detail/:newsId/:newsTitle/:newsBody/:newsCreatedAt/:newsUrl",
    name: "newsDetail",
    component: () => import("../views/web_newsDetail"),
    meta: {
      permissions: [
        {
          access: true,
        },
      ],
      role: "",
    },
  },
  {
    path: "/companies",
    name: "all-companies",
    component: () =>
      import(/*webpackChunkName: "companies"*/ "../views/web_companies"),
    meta: {
      permissions: [
        {
          access: true,
        },
      ],
      role: "",
    },
  },
  {
    path: "/social-video/:videoId",
    name: "social-video",
    component: () =>
      import(/*webpackChunkName: "socialVideo"*/ "../views/socialVideo"),
    meta: {
      permissions: [
        {
          accesss: true,
        },
      ],
      role: "",
    },
  },
  {
    path: "/social-audio/:audioId",
    name: "social-audio",
    component: () =>
      import(/*webpackChunkName: "socialVideo"*/ "../views/socialAudio"),
    meta: {
      permissions: [
        {
          accesss: true,
        },
      ],
      role: "",
    },
  },
  {
    path: "/social-post/:postId",
    name: "social-post",
    component: () =>
      import(/*webpackChunkName: "socialVideo"*/ "../views/socialPost"),
    meta: {
      permissions: [
        {
          accesss: true,
        },
      ],
      role: "",
    },
  },
];
