import Vue from "vue";
import "./plugins/fontawesome";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import "../public/_main.css";
Vue.config.productionTip = false;
Vue.use(require("vue-shortkey"));
import AnimateCSS from 'animate.css';
Vue.use(AnimateCSS);
import jQuery from "jquery";
Vue.prototype.$jQuery = jQuery;
import x5GMaps from "x5-gmaps";
import {Constants} from "./libs/constant";
import ElementUI from "element-ui";
import "./styles.scss";
import numeral from "numeral"

Vue.use(ElementUI);
Vue.use(x5GMaps, Constants.apiKey);
import moment from "moment";
Vue.prototype.$_numeral = numeral;
Vue.use(numeral);

Vue.filter('momentFormatM', (val) => {
    if (val) {
        return moment(val, "YYYY-MM-DD").format('DD-MMMM-YYYY');
    }
    return "";
});
import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing);


new Vue({
    store,
    router,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount("#app");
