//const baseURL = "http://localhost:4000/api/v1";
// const baseURL = "http://localhost:3000/api/v1";
//const baseURL = "http://18.141.182.129:7777/api/v1";
//const baseURL = "https://buyhousing.online/api/v1";
//const baseURL = "https://v1.buyhousing.online/api/v1";

//=========Live
const baseURL = "https://agent.bayonapp.com/api/v1";
const mojokey = "1ec5d762-b790-4288-8256-0a618887ed4c";

//============Test
/*const baseURL = "https://uat.bayonapp.com/api/v1";
const mojokey = "test-9f0eac25-f245-4771-b066-c1193c307a50";*/


export const provider = {
    baseURL: baseURL,
    token: "narongrealestate",
    addProperty: "/property/add",
    updateProperty: "/property/edit",
    subscriptionPayment: "/aba/subscriptionPayment2",
    insertUserPayment: "/userPayment/add",
    insertUserPayment2: "/userPayment/add2",
    mojoauthSendEmail: "https://api.mojoauth.com/users/emailotp",
    mojoauthEmailVerify: "https://api.mojoauth.com/users/emailotp/verify",
    mojoKey: mojokey

};
