import Vue from "vue";
import {provider} from "../service/provider";
import axios from "axios";
import numeral from "numeral";
import moment from "moment";
import {Constants} from "../libs/constant";
import {countyMobileList} from "../libs/countryMobileWithFlag";
import _ from "lodash";
import crypto from "crypto"

Vue.filter("formatNumber", function (x) {
    if (x) {
        let parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    } else {
        return 0;
    }
});
Vue.filter("separateNumber", function (val) {
    if (
        val !== "" &&
        val !== undefined &&
        val !== 0 &&
        val !== "0" &&
        val !== null
    ) {
        val = numeral(val).value() + "";
        let newVal = val.split(".");
        return (
            newVal[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
            (newVal[1] ? "." + newVal[1] : "")
        );
    }
    return "";
});

Vue.filter("formatNumberToKandM", function (num) {
    if (num >= 1000000000) {
        return (num / 1000000000).toFixed(2).replace(/\.0$/, '') + 'B';
    }
    if (num >= 1000000) {
        return (num / 1000000).toFixed(2).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
        return (num / 1000).toFixed(2).replace(/\.0$/, '') + 'K';
    }
    return num;
});
export default {
    name: "MainMixin",
    data() {
        return {
            advertiseList: [],
            advertiseAgentList: [],
            propertyList: [],
            propertyListWeb: [],
            stateList: [],
            locationOptionList: [],
            districtOptionList: [],
            projectOption: [],
            countryList: countyMobileList,
            typeOption: Constants.propertyType,
            priceList: Constants.priceList,
            agentTypeOption: [],
            totalPage: 0,
            config: {},
            totalProperty: 0,
            totalUser: 0,
            totalAgent: 0,
            totalCompany: 0,
            propertiesByAgentId: [],
            agent: {
                name: '',
                email: '',
                phoneNumber: '',
                address: '',
                photo: '',
                totalProperty: '',
                totalClient: ''
            },
            fetchedNews: [],
            fetchedCompanies: [],
            agentsList: [],
            randomAgentsList: []
        };
    },
    methods: {
        formatNumber(x){
            if (x) {
                let parts = x.toString().split(".");
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                return parts.join(".");
            } else {
                return 0;
            }
        },
        formatNumberToKandM(num){
            if (num) {
                if (num >= 1000000000) {
                    return (num / 1000000000).toFixed(2).replace(/\.0$/, '') + 'B';
                }
                if (num >= 1000000) {
                    return (num / 1000000).toFixed(2).replace(/\.0$/, '') + 'M';
                }
                if (num >= 1000) {
                    return (num / 1000).toFixed(2).replace(/\.0$/, '') + 'K';
                }
                return num;
            } else {
                return 0;
            }
        },
        queryProject: _.debounce(function (search, countryId) {
            let vm = this;
            vm.fetchProjectOption(search, countryId || vm.params.countryId);
        }, 500),
        queryLocation: _.debounce(function (countryId) {
            let vm = this;
            vm.fetchLocationOption(countryId || vm.params.countryId);
        }, 500),
        queryDistrict: _.debounce(function (locationId) {
            let vm = this;
            vm.fetchDistrictOption(locationId || vm.params.locationId);
        }, 500)
        ,
        queryState: _.debounce(function (countryId) {
            let vm = this;
            vm.fetchStateOption(countryId);
        }, 500)
        ,
        fetchProperty({
                          search,
                          filter,
                          skip,
                          limit,
                          sortBy,
                          sortDesc,
                          locationId,
                          type,
                          minPrice,
                          maxPrice,
                          groupType,
                          status,
                          isWeb,
                          userId,
                          countryId,
                          favoriteUserId
                      }) {
            let vm = this;
            let url =
                provider.baseURL +
                `/property/fetch?search=${search}&filter=${filter}&skip=${skip}&limit=${limit}&sortBy=${sortBy}&sortDesc=${sortDesc}&locationId=${locationId}&type=${type}&minPrice=${minPrice}&maxPrice=${maxPrice}&groupType=${groupType}&countryId=${countryId}&status=${status}&isWeb=${isWeb}&userId=${userId || ""}&isNotMobile=${true}&favoriteUserId=${favoriteUserId || ""}`;
            return new Promise((resolve, reject) => {
                axios.get(url, {headers: {token: provider.token}}).then(
                    res => {
                        if (res.data.code === 201) {
                            resolve(res.data.data.content);
                            vm.propertyList = (res.data.data && res.data.data.content) || [];
                            vm.totalPage = res.data.data.countContent;
                            vm.totalProperty = res.data.data.totalProperty;
                            vm.totalCompany = res.data.data.totalCompany;
                            vm.totalUser = res.data.data.totalUser;
                            vm.totalAgent = res.data.data.totalAgent;
                        }
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        fetchPropertyAdvertise() {
            let vm = this;
            let currentDate = moment().format("YYYY-MM-DD");
            let countryCode = localStorage.countryCode || "KM";
            let url =
                provider.baseURL +
                `/advertise/fetch?countryCode=${countryCode}&currentDate=${currentDate}`;
            return new Promise((resolve, reject) => {
                axios.get(url, {headers: {token: provider.token}}).then(
                    res => {
                        if (res.data.code === 201) {
                            resolve(res.data.data.content);
                            vm.advertiseList = [];
                            vm.advertiseAgentList = [];
                            res.data.data && res.data.data.content.forEach((d) => {
                                // console.log(d);
                                // console.log(d)
                                // console.log(d)
                                if (d.propertyDoc) {
                                    vm.advertiseList.push(d);
                                    // console.log(vm.advertiseList)
                                } else if (d.agentDoc) {
                                    vm.advertiseAgentList.push(d);
                                }
                            })
                        }
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        fetchWebProperty({countryId}) {
            let vm = this;
            let url =
                provider.baseURL + `/propertyWeb/fetch?countryId=${countryId}`;
            return new Promise((resolve, reject) => {
                axios.get(url, {headers: {token: provider.token}}).then(
                    res => {
                        if (res.data.code === 201) {
                            resolve(res.data.data.content);
                            vm.propertyListWeb =
                                (res.data.data && res.data.data.content) || [];
                        }
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        fetchProjectOption(val, countryId) {
            let vm = this;
            let url =
                provider.baseURL + `/projectOpt/fetch?search=${val}&countryId=${countryId}`;
            return new Promise((resolve, reject) => {
                axios.get(url, {headers: {token: provider.token}}).then(
                    res => {
                        if (res.data.code === 201) {
                            resolve(res.data.data);
                            vm.projectOption =
                                (res.data.data && res.data.data) || [];
                        }
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        fetchLocationOption(countryId) {
            let vm = this;
            let url =
                provider.baseURL + `/location/fetch?countryId=${countryId}&locale=${vm.$i18n.locale}`;
            return new Promise((resolve, reject) => {
                axios.get(url, {headers: {token: provider.token}}).then(
                    res => {
                        if (res.data.code === 201) {
                            resolve(res.data.data);
                            vm.locationOptionList =
                                (res.data.data && res.data.data) || [];
                        }
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        fetchDistrictOption(locationId) {
            let vm = this;
            let url =
                provider.baseURL + `/district/fetch?locationId=${locationId}&locale=${vm.$i18n.locale}`;
            return new Promise((resolve, reject) => {
                axios.get(url, {headers: {token: provider.token}}).then(
                    res => {
                        if (res.data.code === 201) {
                            resolve(res.data.data);
                            vm.districtOptionList =
                                (res.data.data && res.data.data) || [];
                        }
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        fetchStateOption(countryId) {
            let vm = this;
            let url =
                provider.baseURL + `/state/fetch?countryId=${countryId}`;
            return new Promise((resolve, reject) => {
                axios.get(url, {headers: {token: provider.token}}).then(
                    res => {
                        if (res.data.code === 201) {
                            resolve(res.data.data);
                            vm.stateList =
                                (res.data.data && res.data.data) || [];
                        }
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        fetchAgentTypeOption() {
            let vm = this;
            let url =
                provider.baseURL + `/agentType/fetch`;
            return new Promise((resolve, reject) => {
                axios.get(url, {headers: {token: provider.token}}).then(
                    res => {
                        if (res.data.code === 201) {
                            resolve(res.data.data);
                            vm.agentTypeOption =
                                (res.data.data && res.data.data) || [];
                        }
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        /*getHash(transactionId,amount){
            let hash = base64.encode(hash_hmac('sha512', provider.abaMerchantId+ transactionId +amount, provider.abaKey, true));
            return hash;
        }*/
        // ============ Fetch PropertyByAgentID =======================
        fetchPropertyByAgentId(skip, limit, sortBy, sortDes, agentId) {
            let vm = this;
            let url = provider.baseURL + `/property/byAgentId?search=&skip=${skip}&limit=${limit}&sortBy=${sortBy}&sortDesc=${sortDes}&agentId=${agentId}&type=&status=`;
            return new Promise((resolve, reject) => {
                axios.get(url, {headers: {token: provider.token}}).then(
                    res => {
                        if(res.data.code === 201) {
                            resolve(res.data.data);
                            vm.propertiesByAgentId = [];
                            vm.agent.name = res.data.data.agentDoc.name;
                            vm.agent.email = res.data.data.agentDoc.email;
                            vm.agent.phoneNumber = res.data.data.agentDoc.phoneNumber;
                            vm.agent.address = res.data.data.agentDoc.address;
                            vm.agent.photo = res.data.data.agentDoc.photo;
                            vm.agent.totalProperty = res.data.data.countProperty;
                            vm.agent.totalClient = res.data.data.countClient;
                            vm.totalPage = res.data.data.countProperty;
                            res.data.data && res.data.data.content.forEach((d) => {
                                    vm.propertiesByAgentId.push(d);
                            })
                        }
                    },
                    error => {
                        reject(error);
                    }
                )
            })
        },
        fetchNews({search, skip, limit}) {
            let vm = this;
            let countryCode = localStorage.countryCode || "KM";
            let url =
                provider.baseURL + `/notification/fetch?search=${search}&skip=${skip}&limit=${limit}&sortBy=&sortDesc=&countryCode=${countryCode}`;
            return new Promise((resolve, reject) => {
                axios.get(url, {headers: {token: provider.token}}).then(
                    res => {
                        if (res.data.code === 201) {
                            resolve(res.data.data);
                            vm.fetchedNews = (res.data.data && res.data.data.content) || [];
                            vm.totalPage = res.data.data.countContent;
                        }
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        fetchCompanies({search, skip, limit}) {
            let vm = this;
            let countryId = localStorage.countryCode || "KM";
            let url =
                provider.baseURL + `/agency/fetch?search=${search}&skip=${skip}&limit=${limit}&sortBy=&sortDesc=&countryId=${countryId}`;
            return new Promise((resolve, reject) => {
                axios.get(url, {headers: {token: provider.token}}).then(
                    res => {
                        if (res.data.code === 201) {
                            resolve(res.data.data);
                            vm.fetchedCompanies = (res.data.data && res.data.data.content) || [];
                            vm.totalPage = res.data.data.countContent;
                        }
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        fetchAgents({search, skip, limit}) {
            let vm = this;
            let countryId = localStorage.countryCode || "KM";
            let url =
                provider.baseURL + `/agent/fetch?search=${search}&skip=${skip}&limit=${limit}&sortBy=&sortDesc=&countryId=${countryId}`;
            return new Promise((resolve, reject) => {
                axios.get(url, {headers: {token: provider.token}}).then(
                    res => {
                        if (res.data.code === 201) {
                            resolve(res.data.data);
                            vm.agentsList = (res.data.data && res.data.data.content) || [];
                            vm.totalPage = res.data.data.countContent;
                        }
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        fetchRandomAgents({countryId}) {
            let vm = this;
            let url =
                provider.baseURL + `/agent/fetchRandom?countryId=${countryId}`;
            return new Promise((resolve, reject) => {
                axios.get(url, {headers: {token: provider.token}}).then(
                    res => {
                        if (res.data.code === 201) {
                            resolve(res.data.data);
                            vm.randomAgentsList = (res.data.data && res.data.data.content) || [];
                        }
                    },
                    error => {
                        reject(error);
                    }
                );
            });
        },
        getHashForPassword(password) {
            let key = Constants.secretKeyForPassword.toString('utf8');
            let pass = password.toString('utf8');
            let hash = crypto.createHmac("sha512", key);
            hash.update(pass);
            let hashed_data = hash.digest();
            return hashed_data.toString('base64');
        },
        getCurrencySymbol(countryCode){
            let currencySymbol = "$";
            switch(countryCode){
                case "MM":
                  currencySymbol = "Lakh";
                  break;
                case "KH":
                  currencySymbol = "$";
                  break;
                case "VN":
                  currencySymbol = "₫";
                  break;
                case "SG":
                  currencySymbol = "S$";
                  break;
                case "PH":
                  currencySymbol = "₱";
                  break;
                case "MY":
                  currencySymbol = "RM";
                  break;
                case "LA":
                  currencySymbol = "₭";
                  break;
                case "JP":
                  currencySymbol = "¥";
                  break;
                case "ID":
                  currencySymbol = "Rp";
                  break;
            }
            return currencySymbol;
        }
    }
};
