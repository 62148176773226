import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);
/*import km from "../locals/kmVuetify";
import en from "vuetify/es5/locale/en";*/
import i18n from "../i18n";
export default new Vuetify({
  /* lang:{
        locals:{en,km},
        current:'en'
    }*/

  lang: {
    t: (key, ...params) => i18n.t(key, params)
  }
});
