let secret = "narongrealestate";
let apiKey = "AIzaSyDoehcSUDndN3kbunOWwP5qUC199k6Th4w";
let facebookId = "749415358997920";
let facebookSecret = "db943e49cac2e00cb6d0d2bf737707cd";
let googleClientId ="1081651083189-seeofeio5kff9v116m182trqf7dsvdnv.apps.googleusercontent.com";
let googleSecret = "tt5Che70wgt-y3ELZoo58XwA";
let domainName = "bayonapp.com";
// let domainName = "localhost:3000";

//========Live======
const ABA_PAYWAY_API_URL = "https://checkout.payway.com.kh/api/payment-gateway/v1/payments/purchase";
const ABA_KEY = "747fd1494ecf25ab62842fe406a759b8";
const ABA_Merchant_Id = "bayonapppromote";
const ABA_CARD = "4026459992389502";
const ABA_EXP = "0222";
const ABA_CVV = "066";
const ipWhiteList = "http://128.199.233.175/";

//=======Test======

// const ABA_PAYWAY_API_URL = "https://checkout-uat.payway.com.kh/api/payment-gateway/v1/payments/purchase";
// const ABA_KEY = "0608787ba080b4878c427aaecdeb9ac9";
// const ABA_Merchant_Id = "bayonapp";
// const ABA_CARD = "4026459992389502";
// const ABA_EXP = "0222";
// const ABA_CVV = "066";
// const ipWhiteList = "http://58.97.228.244";




let defaultRoles = [
    "Admin",
    "Member",
    "Cashier",
    "Create",
    "Update",
    "Delete",
    "Setting",
    "Data",
    "Report",
    "Control User"
];

let PickDateListRange = [
    "today",
    "thisMonth",
    "lastMonth",
    "quarter1",
    "quarter2",
    "quarter3",
    "quarter4",
    "semester1",
    "semester2"
];
let PickDateListRangeDay = [
    "today",
    "yesterday",
    "thisWeek",
    "thisMonth",
    "lastMonth",
    "quarter1",
    "quarter2",
    "quarter3",
    "quarter4"
];
// let PickDateList = ['today', 'yesterday', 'thisWeek', 'lastMonth'];

let Gender = [
    {label: "male", value: "1"},
    {label: "female", value: "2"}
];

let Language = [
    {label: "en", value: "en"},
    {label: "km", value: "km"}
];

let TypePaidCommissionOption = [
    {label: "Full Paid", value: "fullPaid"},
    {label: "By Percent Paid", value: "byPercentPaid"}
];

let StaffType = [
    {label: "Full Time", value: "fullTime"},
    {label: "Part Time", value: "partTime"}
];

let TitleType = [
    {label: "noTitle", value: "noTitle"},
    {label: "softTitle", value: "softTitle"},
    {label: "hardTitle", value: "hardTitle"}
];

let Setting = ["Admin", "Setting", "Super"];
let Data = ["Admin", "Data", "Super"];
let Report = ["Admin", "Report", "Super"];

let ControlUser = ["Control User", "Super"];

let Super = ["Super"];

let Create = ["Create", "Super"];
let Delete = ["Delete", "Super"];

let Update = ["Update", "Super"];

let defaultModules = ["Network"];
let Currency = [
    {label: "USD", value: "USD"},
    {label: "KHR", value: "KHR"},
    {label: "THB", value: "THB"}
];
let dimensionList = [
    {label: "m", value: "m"},
    {label: "ft", value: "ft"},
];

let ProductStatusListFilter = ["All", "Free", "Blocked", "Booking", "Sold"];
let ProductStatusList = ["Free", "Blocked", "Booking", "Sold"];
let NationalType = [
    {value: "Identity Card", label: "អត្តសញ្ញាណប័ណ្ណ (Identity Card)"},
    {value: "Family Book", label: "សៀវភៅគ្រួសារ (Family Book)"},
    {value: "Passport", label: "លិខិតឆ្លងដែន (Passport)"},
    {value: "Birth Certificate", label: "សំបុត្រកំណើត (Birth Certificate)"}
];

let PropertyType = [
    {label: "residential", value: "residential", disabled: true},
    {label: "land", value: "land"},
    {label: "house", value: "house"},
    {label: "room", value: "room"},
    {label: "condo", value: "condo"},
    {label: "apartment", value: "apartment"},
    {label: "farmLand", value: "farmLand"},
    {
        label: "roomForRent",
        value: "roomForRent"
    },
    {
        label: "twinVilla",
        value: "twinVilla"
    },
    {
        label: "queenVilla",
        value: "queenVilla"
    },
    {
        label: "shopHouse",
        value: "shopHouse"
    },
    {
        label: "office",
        value: "office"
    },
    {
        label: "warehouse",
        value: "warehouse"
    }
    // {label: "apartment", value: "apartment"},
    // {label: "villa", value: "villa"},
    /*{label: "townhouse", value: "townhouse"},
      {label: "penthouse", value: "penthouse"},
      {label: "duplex", value: "duplex"},
      {label: "wholeBuilding", value: "wholeBuilding"},
      {label: "bunglow", value: "bunglow"},
      {label: "hotelApartment", value: "hotelApartment"},
      {label: "labourCamp", value: "labourCamp"},*/
    // {label: "commercial", value: "commercial", disabled: true},
    // {label: "office", value: "office"},
    // {label: "retail", value: "retail"},
    // {label: "warehouse", value: "warehouse"}
    // {label: "shop", value: "shop"},
    // {label: "showRoom", value: "showRoom"},
    // {label: "fullFloor", value: "fullFloor"},
    // {label: "building", value: "building"}
];
let Type = [
    {label: "property", value: "property"}
    /*{label: "product", value: "product"},
      {label: "service", value: "service"},
      {label: "architects", value: "architects"},
      {label: "buyingGuide", value: "buyingGuide"},
      {label: "designIdea", value: "designIdea"},*/
];

let priceList = [
    0,
    2000,
    3000,
    4000,
    5000,
    6000,
    7000,
    8000,
    9000,
    10000,
    11000,
    12000,
    13000,
    14000,
    15000,
    17000,
    20000,
    22000,
    25000,
    27000,
    30000,
    32000,
    35000,
    37000,
    40000,
    50000,
    60000,
    70000,
    80000,
    90000,
    100000,
    120000,
    140000,
    160000,
    180000,
    200000,
    220000,
    240000,
    260000,
    280000,
    300000,
    320000,
    340000,
    360000,
    380000,
    400000,
    420000,
    440000,
    460000,
    480000,
    500000,
    600000,
    700000,
    800000,
    900000,
    1000000,
    1200000,
    1400000,
    1600000,
    1800000,
    2000000
];

let amenityList = [
    {name: "Pool", value: "pool"},
    {name: "Garden", value: "garden"},
    {name: "Internet", value: "internet"},
    {name: "Gym/Fitness", value: "gym/fitness"},
    {name: "Balcony", value: "balcony"},
    {name: "Non-Flooding", value: "non-flooding"},
    {name: "Car Parking", value: "car parking"},
    {name: "On main road", value: "on main road"},
    {name: "Fire sprinkler system", value: "fire sprinkler system"},
    {name: "Pet Friendly", value: "pet friendly"},
    {name: "Reception 24/7", value: "reception 24/7"},
    {name: "Playground", value: "playground"},
    {name: "Video Security", value: "video security"},
    {name: "Lift/Elevator", value: "lift/elevator"},
    {name: "Swimming Pool", value: "swimming pool"},
    {name: "Jacuzzi", value: "jacuzzi"},
    {name: "Sauna", value: "sauna"},
    {name: "Common Area", value: "common area"},
    {name: "Sports Facilities", value: "sports facilities"},
];
let education = [
    {
        "label": "Primary School",
        "value": "primary_school"
    },
    {
        "label": "High School",
        "value": "high_school"
    },
    {
        "label": "Graduated",
        "value": "graduated"
    },
    {
        "label": "Master Degree",
        "value": "master_degree"
    },
    {
        "label": "Phd Degree",
        "value": "phd_degree"
    }
];
let userType = [
    {
        "label": "Buyer",
        "value": "buyer"
    },
    {
        "label": "Seller",
        "value": "seller"
    },
    {
        "label": "Agent",
        "value": "agent"
    },
    {
        "label": "Company",
        "value": "company"
    },
    {
        "label": "Investor",
        "value": "investor"
    }
];
let occupation = [
    {
        "label": "Farmer",
        "value": "farmer"
    },
    {
        "label": "Employee",
        "value": "employee"
    },
    {
        "label": "Entrepreneur",
        "value": "entrepreneur"
    },
    {
        "label": "Government Officer",
        "value": "government_officer"
    }
];
let yearlyIncome = [
    {
        "label": "Less Than 10000",
        "value": "less_than_10000"
    },
    {
        "label": "Less Than 30000",
        "value": "less_than_30000"
    },
    {
        "label": "Less Than 50000",
        "value": "less_than_50000"
    },
    {
        "label": "Less Than 70000",
        "value": "less_than_70000"
    },
    {
        "label": "Less Than 100000",
        "value": "less_than_100000"
    }
];
let jobLevel = [
    {
        "label": "Junior Level",
        "value": "junior_level"
    },
    {
        "label": "Officer Level",
        "value": "officer_level"
    },
    {
        "label": "Senior Level",
        "value": "senior_level"
    },
    {
        "label": "Manager Level",
        "value": "manager_level"
    }
]
const secretKeyForPassword = '747fd1494ecf25ab62842fe406a759b8';

import {en} from "../locals/en";
import {km} from "../locals/km";

export const t = {en, km};

export const Constants = {
    rolesOption: defaultRoles,
    modulesOption: defaultModules,
    setting: Setting,
    data: Data,
    report: Report,
    controlUser: ControlUser,
    super: Super,
    create: Create,
    delete: Delete,
    update: Update,
    secret: secret,
    gender: Gender,
    staffType: StaffType,
    currency: Currency,
    productStatusList: ProductStatusList,
    typePaidCommissionOption: TypePaidCommissionOption,
    productStatusListFilter: ProductStatusListFilter,
    pickDateListRange: PickDateListRange,
    pickDateListRangeDay: PickDateListRangeDay,
    nationalType: NationalType,
    language: Language,
    titleType: TitleType,
    apiKey: apiKey,
    facebookId: facebookId,
    facebookSecret: facebookSecret,
    domainName: domainName,
    propertyType: PropertyType,
    type: Type,
    googleClientId: googleClientId,
    googleSecret: googleSecret,
    priceList: priceList,
    dimensionList: dimensionList,
    amenityList:amenityList,
    education: education,
    userType: userType,
    occupation: occupation,
    yearlyIncome: yearlyIncome,
    jobLevel: jobLevel,

    abaPayWayApiUrl: ABA_PAYWAY_API_URL,
    abaKey: ABA_KEY,
    abaMerchantId: ABA_Merchant_Id,
    abaCard: ABA_CARD,
    abaExp: ABA_EXP,
    abaCVV: ABA_CVV,
    ipWhiteList: ipWhiteList,
    secretKeyForPassword: secretKeyForPassword
};
