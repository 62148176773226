import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

export const webview = [
    {
        path: "/webview-termofuse",
        name: "webview termofuse",
        component: () =>
            import(/*webpackChunkName: "webview_termOfUse"*/ "../views/webview/webview_termOfUse"),
        
    },
    {
        path: "/webview-reports/:userId",
        name: "webview reports",
        component: () =>
            import(/*webpackChunkName: "webview_reports"*/ "../views/webview/webview_reports"),
        
    },
    {
        path: "/webview-general-reports/:countryCode",
        name: "webview general reports",
        component: () =>
            import(/*webpackChunkName: "webview_reports"*/ "../views/webview/webview_generalReports"),
        
    },
];