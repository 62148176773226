export const countyMobileList = [
    /*{
      name: "Afghanistan",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Afghanistan.svg",
      number: "+93",
      value: "AF",
      timezones: ["Asia/Kabul"],
      position: { lat: 33, lng: 65 },
      latlng: [33, 65],
      capital: "Kabul"
    },
    {
      name: "Albania",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/3/36/Flag_of_Albania.svg",
      number: "+355",
      value: "AL",
      timezones: ["Europe/Tirane"],
      position: { lat: 41, lng: 20 },
      latlng: [41, 20],
      capital: "Tirana"
    },
    {
      name: "Algeria",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Algeria.svg",
      number: "+213",
      value: "DZ",
      timezones: ["Africa/Algiers"],
      position: { lat: 28, lng: 3 },
      latlng: [28, 3],
      capital: "Algiers"
    },
    {
      name: "Andorra",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Andorra.svg",
      number: "+376",
      value: "AD",
      timezones: ["Europe/Andorra"],
      position: { lat: 42.5, lng: 1.5 },
      latlng: [42.5, 1.5],
      capital: "Andorra la Vella"
    },
    {
      name: "Angola",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/9/9d/Flag_of_Angola.svg",
      number: "+244",
      value: "AO",
      timezones: ["Africa/Luanda"],
      position: { lat: -12.5, lng: 18.5 },
      latlng: [-12.5, 18.5],
      capital: "Luanda"
    },
    {
      name: "Anguilla",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Anguilla.svg",
      number: "+1264",
      value: "AI",
      timezones: ["America/Anguilla"],
      position: { lat: 18.25, lng: -63.16666666 },
      latlng: [18.25, -63.16666666],
      capital: "The Valley"
    },
    {
      name: "Antigua and Barbuda",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Antigua_and_Barbuda.svg",
      number: "+1268",
      value: "AG",
      timezones: ["America/Antigua"],
      position: { lat: 17.05, lng: -61.8 },
      latlng: [17.05, -61.8],
      capital: "Saint John's"
    },
    {
      name: "Argentina",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/1/1a/Flag_of_Argentina.svg",
      number: "+54",
      value: "AR",
      timezones: [
        "America/Argentina/Buenos_Aires",
        "America/Argentina/Cordoba",
        "America/Argentina/Salta",
        "America/Argentina/Jujuy",
        "America/Argentina/Tucuman",
        "America/Argentina/Catamarca",
        "America/Argentina/La_Rioja",
        "America/Argentina/San_Juan",
        "America/Argentina/Mendoza",
        "America/Argentina/San_Luis",
        "America/Argentina/Rio_Gallegos",
        "America/Argentina/Ushuaia"
      ],
      position: { lat: -34, lng: -64 },
      latlng: [-34, -64],
      capital: "Buenos Aires"
    },
    {
      name: "Armenia",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/2/2f/Flag_of_Armenia.svg",
      number: "+374",
      value: "AM",
      timezones: ["Asia/Yerevan"],
      position: { lat: 40, lng: 45 },
      latlng: [40, 45],
      capital: "Yerevan"
    },
    {
      name: "Aruba",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Aruba.svg",
      number: "+297",
      value: "AW",
      timezones: ["America/Aruba"],
      position: { lat: 12.5, lng: -69.96666666 },
      latlng: [12.5, -69.96666666],
      capital: "Oranjestad"
    },
    {
      name: "Australia",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_Australia_%28converted%29.svg",
      number: "+61",
      value: "AU",
      timezones: [
        "Australia/Lord_Howe",
        "Antarctica/Macquarie",
        "Australia/Hobart",
        "Australia/Currie",
        "Australia/Melbourne",
        "Australia/Sydney",
        "Australia/Broken_Hill",
        "Australia/Brisbane",
        "Australia/Lindeman",
        "Australia/Adelaide",
        "Australia/Darwin",
        "Australia/Perth",
        "Australia/Eucla"
      ],
      position: { lat: -27, lng: 133 },
      latlng: [-27, 133],
      capital: "Canberra"
    },
    {
      name: "Austria",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_Austria.svg",
      number: "+43",
      value: "AT",
      timezones: ["Europe/Vienna"],
      position: { lat: 47.33333333, lng: 13.33333333 },
      latlng: [47.33333333, 13.33333333],
      capital: "Vienna"
    },
    {
      name: "Azerbaijan",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Azerbaijan.svg",
      number: "+994",
      value: "AZ",
      timezones: ["Asia/Baku"],
      position: { lat: 40.5, lng: 47.5 },
      latlng: [40.5, 47.5],
      capital: "Baku"
    },
    {
      name: "Bahamas",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/9/93/Flag_of_the_Bahamas.svg",
      number: "+1242",
      value: "BS",
      timezones: ["America/Nassau"],
      position: { lat: 24.25, lng: -76 },
      latlng: [24.25, -76],
      capital: "Nassau"
    },
    {
      name: "Bahrain",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Bahrain.svg",
      number: "+973",
      value: "BH",
      timezones: ["Asia/Bahrain"],
      position: { lat: 26, lng: 50.55 },
      latlng: [26, 50.55],
      capital: "Manama"
    },
    {
      name: "Bangladesh",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/f/f9/Flag_of_Bangladesh.svg",
      number: "+880",
      value: "BD",
      timezones: ["Asia/Dhaka"],
      position: { lat: 24, lng: 90 },
      latlng: [24, 90],
      capital: "Dhaka"
    },
    {
      name: "Barbados",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/e/ef/Flag_of_Barbados.svg",
      number: "+1246",
      value: "BB",
      timezones: ["America/Barbados"],
      position: { lat: 13.16666666, lng: -59.53333333 },
      latlng: [13.16666666, -59.53333333],
      capital: "Bridgetown"
    },
    {
      name: "Belarus",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/8/85/Flag_of_Belarus.svg",
      number: "+375",
      value: "BY",
      timezones: ["Europe/Minsk"],
      position: { lat: 53, lng: 28 },
      latlng: [53, 28],
      capital: "Minsk"
    },
    {
      name: "Belgium",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Belgium.svg",
      number: "+32",
      value: "BE",
      timezones: ["Europe/Brussels"],
      position: { lat: 50.83333333, lng: 4 },
      latlng: [50.83333333, 4],
      capital: "Brussels"
    },
    {
      name: "Belize",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/e/e7/Flag_of_Belize.svg",
      number: "+501",
      value: "BZ",
      timezones: ["America/Belize"],
      position: { lat: 17.25, lng: -88.75 },
      latlng: [17.25, -88.75],
      capital: "Belmopan"
    },
    {
      name: "Benin",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Benin.svg",
      number: "+229",
      value: "BJ",
      timezones: ["Africa/Porto-Novo"],
      position: { lat: 9.5, lng: 2.25 },
      latlng: [9.5, 2.25],
      capital: "Porto-Novo"
    },
    {
      name: "Bermuda",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bermuda.svg",
      number: "+1441",
      value: "BM",
      timezones: ["Atlantic/Bermuda"],
      position: { lat: 32.33333333, lng: -64.75 },
      latlng: [32.33333333, -64.75],
      capital: "Hamilton"
    },
    {
      name: "Bhutan",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/9/91/Flag_of_Bhutan.svg",
      number: "+975",
      value: "BT",
      timezones: ["Asia/Thimphu"],
      position: { lat: 27.5, lng: 90.5 },
      latlng: [27.5, 90.5],
      capital: "Thimphu"
    },
    {
      name: "Bosnia and Herzegovina",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bosnia_and_Herzegovina.svg",
      number: "+387",
      value: "BA",
      timezones: ["Europe/Sarajevo"],
      position: { lat: 44, lng: 18 },
      latlng: [44, 18],
      capital: "Sarajevo"
    },
    {
      name: "Botswana",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Botswana.svg",
      number: "+267",
      value: "BW",
      timezones: ["Africa/Gaborone"],
      position: { lat: -22, lng: 24 },
      latlng: [-22, 24],
      capital: "Gaborone"
    },
    {
      name: "Bouvet Island",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg",
      number: "+55",
      value: "BV",
      timezones: ["Europe/Oslo"],
      position: { lat: -54.43333333, lng: 3.4 },
      latlng: [-54.43333333, 3.4],
      capital: null
    },
    {
      name: "Brazil",
      flag: "https://upload.wikimedia.org/wikipedia/en/0/05/Flag_of_Brazil.svg",
      number: "+55",
      value: "BR",
      timezones: [
        "America/Noronha",
        "America/Belem",
        "America/Fortaleza",
        "America/Recife",
        "America/Araguaina",
        "America/Maceio",
        "America/Bahia",
        "America/Sao_Paulo",
        "America/Campo_Grande",
        "America/Cuiaba",
        "America/Santarem",
        "America/Porto_Velho",
        "America/Boa_Vista",
        "America/Manaus",
        "America/Eirunepe",
        "America/Rio_Branco"
      ],
      position: { lat: -10, lng: -55 },
      latlng: [-10, -55],
      capital: "Brasília"
    },
    {
      name: "British Indian Ocean Territory",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_the_Commissioner_of_the_British_Indian_Ocean_Territory.svg",
      number: "+246",
      value: "IO",
      timezones: ["Indian/Chagos"],
      position: { lat: -6, lng: 71.5 },
      latlng: [-6, 71.5],
      capital: "Diego Garcia"
    },
    */{
      name: "Brunei Darussalam",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Brunei.svg",
      number: "+673",
      value: "BN",
      timezones: ["Asia/Brunei"],
      position: { lat: 4.5, lng: 114.66666666 },
      latlng: [4.5, 114.66666666],
      capital: "Bandar Seri Begawan"
    },
    /*
    {
      name: "Bulgaria",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Bulgaria.svg",
      number: "+359",
      value: "BG",
      timezones: ["Europe/Sofia"],
      position: { lat: 43, lng: 25 },
      latlng: [43, 25],
      capital: "Sofia"
    },
    {
      name: "Burkina Faso",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Burkina_Faso.svg",
      number: "+226",
      value: "BF",
      timezones: ["Africa/Ouagadougou"],
      position: { lat: 13, lng: -2 },
      latlng: [13, -2],
      capital: "Ouagadougou"
    },
    {
      name: "Burundi",
      flag:
        "https://upload.wikimedia.org/wikipedia/commons/5/50/Flag_of_Burundi.svg",
      number: "+257",
      value: "BI",
      timezones: ["Africa/Bujumbura"],
      position: { lat: -3.5, lng: 30 },
      latlng: [-3.5, 30],
      capital: "Bujumbura"
    },
    */
    {
        name: "Cambodia",
        flag:
            "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_Cambodia.svg",
        number: "+855",
        value: "KH",
        timezones: ["Asia/Phnom_Penh"],
        position: {lat: 13, lng: 105},
        latlng: [13, 105],
        capital: "Phnom Penh"
    },
    /*
  {
    name: "Cameroon",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_Cameroon.svg",
    number: "+237",
    value: "CM",
    timezones: ["Africa/Douala"],
    position: { lat: 6, lng: 12 },
    latlng: [6, 12],
    capital: "Yaoundé"
  },
  {
    name: "Canada",
    flag: "https://upload.wikimedia.org/wikipedia/en/c/cf/Flag_of_Canada.svg",
    number: "+1",
    value: "CA",
    timezones: [
      "America/St_Johns",
      "America/Halifax",
      "America/Glace_Bay",
      "America/Moncton",
      "America/Goose_Bay",
      "America/Blanc-Sablon",
      "America/Toronto",
      "America/Nipigon",
      "America/Thunder_Bay",
      "America/Iqaluit",
      "America/Pangnirtung",
      "America/Atikokan",
      "America/Winnipeg",
      "America/Rainy_River",
      "America/Resolute",
      "America/Rankin_Inlet",
      "America/Regina",
      "America/Swift_Current",
      "America/Edmonton",
      "America/Cambridge_Bay",
      "America/Yellowknife",
      "America/Inuvik",
      "America/Creston",
      "America/Dawson_Creek",
      "America/Fort_Nelson",
      "America/Vancouver",
      "America/Whitehorse",
      "America/Dawson"
    ],
    position: { lat: 60, lng: -95 },
    latlng: [60, -95],
    capital: "Ottawa"
  },
  {
    name: "Cape Verde",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Cape_Verde.svg",
    number: "+238",
    value: "CV",
    timezones: ["Atlantic/Cape_Verde"],
    position: { lat: 16, lng: -24 },
    latlng: [16, -24],
    capital: "Praia"
  },
  {
    name: "Cayman Islands",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_the_Cayman_Islands.svg",
    number: "+1345",
    value: "KY",
    timezones: ["America/Cayman"],
    position: { lat: 19.5, lng: -80.5 },
    latlng: [19.5, -80.5],
    capital: "George Town"
  },
  {
    name: "Central African Republic",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/6/6f/Flag_of_the_Central_African_Republic.svg",
    number: "+236",
    value: "CF",
    timezones: ["Africa/Bangui"],
    position: { lat: 7, lng: 21 },
    latlng: [7, 21],
    capital: "Bangui"
  },
  {
    name: "Chad",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/4/4b/Flag_of_Chad.svg",
    number: "+235",
    value: "TD",
    timezones: ["Africa/Ndjamena"],
    position: { lat: 15, lng: 19 },
    latlng: [15, 19],
    capital: "N'Djamena"
  },
  {
    name: "Chile",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/7/78/Flag_of_Chile.svg",
    number: "+56",
    value: "CL",
    timezones: ["America/Santiago", "Pacific/Easter"],
    position: { lat: -30, lng: -71 },
    latlng: [-30, -71],
    capital: "Santiago"
  },
  */
    {
        name: "China",
        flag:
            "https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_the_People%27s_Republic_of_China.svg",
        number: "+86",
        value: "CN",
        timezones: ["Asia/Shanghai", "Asia/Urumqi"],
        position: {lat: 35, lng: 105},
        latlng: [35, 105],
        capital: "Beijing"
    },
    /*
  {
    name: "Christmas Island",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/6/67/Flag_of_Christmas_Island.svg",
    number: "+61",
    value: "CX",
    timezones: ["Indian/Christmas"],
    position: { lat: -10.5, lng: 105.66666666 },
    latlng: [-10.5, 105.66666666],
    capital: "Flying Fish Cove"
  },
  {
    name: "Cocos (Keeling) Islands",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_the_Cocos_%28Keeling%29_Islands.svg",
    number: "+61",
    value: "CC",
    timezones: ["Indian/Cocos"],
    position: { lat: -12.5, lng: 96.83333333 },
    latlng: [-12.5, 96.83333333],
    capital: "West Island"
  },
  {
    name: "Colombia",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Colombia.svg",
    number: "+57",
    value: "CO",
    timezones: ["America/Bogota"],
    position: { lat: 4, lng: -72 },
    latlng: [4, -72],
    capital: "Bogotá"
  },
  {
    name: "Comoros",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/9/94/Flag_of_the_Comoros.svg",
    number: "+269",
    value: "KM",
    timezones: ["Indian/Comoro"],
    position: { lat: -12.16666666, lng: 44.25 },
    latlng: [-12.16666666, 44.25],
    capital: "Moroni"
  },
  {
    name: "Congo",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_the_Republic_of_the_Congo.svg",
    number: "+242",
    value: "CG",
    timezones: ["Africa/Brazzaville"],
    position: { lat: -1, lng: 15 },
    latlng: [-1, 15],
    capital: "Brazzaville"
  },
  {
    name: "Cook Islands",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/3/35/Flag_of_the_Cook_Islands.svg",
    number: "+682",
    value: "CK",
    timezones: ["Pacific/Rarotonga"],
    position: { lat: -21.23333333, lng: -159.76666666 },
    latlng: [-21.23333333, -159.76666666],
    capital: "Avarua"
  },
  {
    name: "Costa Rica",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Costa_Rica_%28state%29.svg",
    number: "+506",
    value: "CR",
    timezones: ["America/Costa_Rica"],
    position: { lat: 10, lng: -84 },
    latlng: [10, -84],
    capital: "San José"
  },
  {
    name: "Croatia",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Croatia.svg",
    number: "+385",
    value: "HR",
    timezones: ["Europe/Zagreb"],
    position: { lat: 45.16666666, lng: 15.5 },
    latlng: [45.16666666, 15.5],
    capital: "Zagreb"
  },
  {
    name: "Cuba",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/b/bd/Flag_of_Cuba.svg",
    number: "+53",
    value: "CU",
    timezones: ["America/Havana"],
    position: { lat: 21.5, lng: -80 },
    latlng: [21.5, -80],
    capital: "Havana"
  },
  {
    name: "Cyprus",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Cyprus.svg",
    number: "+357",
    value: "CY",
    timezones: ["Asia/Nicosia"],
    position: { lat: 35, lng: 33 },
    latlng: [35, 33],
    capital: "Nicosia"
  },
  {
    name: "Czech Republic",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_Czech_Republic.svg",
    number: "+420",
    value: "CZ",
    timezones: ["Europe/Prague"],
    position: { lat: 49.75, lng: 15.5 },
    latlng: [49.75, 15.5],
    capital: "Prague"
  },
  {
    name: "Denmark",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Denmark.svg",
    number: "+45",
    value: "DK",
    timezones: ["Europe/Copenhagen"],
    position: { lat: 56, lng: 10 },
    latlng: [56, 10],
    capital: "Copenhagen"
  },
  {
    name: "Djibouti",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_Djibouti.svg",
    number: "+253",
    value: "DJ",
    timezones: ["Africa/Djibouti"],
    position: { lat: 11.5, lng: 43 },
    latlng: [11.5, 43],
    capital: "Djibouti"
  },
  {
    name: "Dominica",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/c/c4/Flag_of_Dominica.svg",
    number: "+1767",
    value: "DM",
    timezones: ["America/Dominica"],
    position: { lat: 15.41666666, lng: -61.33333333 },
    latlng: [15.41666666, -61.33333333],
    capital: "Roseau"
  },
  {
    name: "Dominican Republic",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_the_Dominican_Republic.svg",
    number: "+1849",
    value: "DO",
    timezones: ["America/Santo_Domingo"],
    position: { lat: 19, lng: -70.66666666 },
    latlng: [19, -70.66666666],
    capital: "Santo Domingo"
  },
  {
    name: "Ecuador",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/e/e8/Flag_of_Ecuador.svg",
    number: "+593",
    value: "EC",
    timezones: ["America/Guayaquil", "Pacific/Galapagos"],
    position: { lat: -2, lng: -77.5 },
    latlng: [-2, -77.5],
    capital: "Quito"
  },
  {
    name: "Egypt",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Egypt.svg",
    number: "+20",
    value: "EG",
    timezones: ["Africa/Cairo"],
    position: { lat: 27, lng: 30 },
    latlng: [27, 30],
    capital: "Cairo"
  },
  {
    name: "El Salvador",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_El_Salvador.svg",
    number: "+503",
    value: "SV",
    timezones: ["America/El_Salvador"],
    position: { lat: 13.83333333, lng: -88.91666666 },
    latlng: [13.83333333, -88.91666666],
    capital: "San Salvador"
  },
  {
    name: "Equatorial Guinea",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Equatorial_Guinea.svg",
    number: "+240",
    value: "GQ",
    timezones: ["Africa/Malabo"],
    position: { lat: 2, lng: 10 },
    latlng: [2, 10],
    capital: "Malabo"
  },
  {
    name: "Eritrea",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/2/29/Flag_of_Eritrea.svg",
    number: "+291",
    value: "ER",
    timezones: ["Africa/Asmara"],
    position: { lat: 15, lng: 39 },
    latlng: [15, 39],
    capital: "Asmara"
  },
  {
    name: "Estonia",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/8/8f/Flag_of_Estonia.svg",
    number: "+372",
    value: "EE",
    timezones: ["Europe/Tallinn"],
    position: { lat: 59, lng: 26 },
    latlng: [59, 26],
    capital: "Tallinn"
  },
  {
    name: "Ethiopia",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/7/71/Flag_of_Ethiopia.svg",
    number: "+251",
    value: "ET",
    timezones: ["Africa/Addis_Ababa"],
    position: { lat: 8, lng: 38 },
    latlng: [8, 38],
    capital: "Addis Ababa"
  },
  {
    name: "Falkland Islands (Malvinas)",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_Falkland_Islands.svg",
    number: "+500",
    value: "FK",
    timezones: ["Atlantic/Stanley"],
    position: { lat: -51.75, lng: -59 },
    latlng: [-51.75, -59],
    capital: "Stanley"
  },
  {
    name: "Faroe Islands",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/3/3c/Flag_of_the_Faroe_Islands.svg",
    number: "+298",
    value: "FO",
    timezones: ["Atlantic/Faroe"],
    position: { lat: 62, lng: -7 },
    latlng: [62, -7],
    capital: "Tórshavn"
  },
  {
    name: "Fiji",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/b/ba/Flag_of_Fiji.svg",
    number: "+679",
    value: "FJ",
    timezones: ["Pacific/Fiji"],
    position: { lat: -18, lng: 175 },
    latlng: [-18, 175],
    capital: "Suva"
  },
  {
    name: "Finland",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Finland.svg",
    number: "+358",
    value: "FI",
    timezones: ["Europe/Helsinki"],
    position: { lat: 64, lng: 26 },
    latlng: [64, 26],
    capital: "Helsinki"
  },
  {
    name: "France",
    flag: "https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg",
    number: "+33",
    value: "FR",
    timezones: ["Europe/Paris"],
    position: { lat: 46, lng: 2 },
    latlng: [46, 2],
    capital: "Paris"
  },
  {
    name: "French Guiana",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/2/29/Flag_of_French_Guiana.svg",
    number: "+594",
    value: "GF",
    timezones: ["America/Cayenne"],
    position: { lat: 4, lng: -53 },
    latlng: [4, -53],
    capital: "Cayenne"
  },
  {
    name: "French Polynesia",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/d/db/Flag_of_French_Polynesia.svg",
    number: "+689",
    value: "PF",
    timezones: ["Pacific/Tahiti", "Pacific/Marquesas", "Pacific/Gambier"],
    position: { lat: -15, lng: -140 },
    latlng: [-15, -140],
    capital: "Papeetē"
  },
  {
    name: "Gabon",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/0/04/Flag_of_Gabon.svg",
    number: "+241",
    value: "GA",
    timezones: ["Africa/Libreville"],
    position: { lat: -1, lng: 11.75 },
    latlng: [-1, 11.75],
    capital: "Libreville"
  },
  {
    name: "Gambia",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_The_Gambia.svg",
    number: "+220",
    value: "GM",
    timezones: ["Africa/Banjul"],
    position: { lat: 13.46666666, lng: -16.56666666 },
    latlng: [13.46666666, -16.56666666],
    capital: "Banjul"
  },
  {
    name: "Georgia",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Georgia.svg",
    number: "+995",
    value: "GE",
    timezones: ["Asia/Tbilisi"],
    position: { lat: 42, lng: 43.5 },
    latlng: [42, 43.5],
    capital: "Tbilisi"
  },
  {
    name: "Germany",
    flag: "https://upload.wikimedia.org/wikipedia/en/b/ba/Flag_of_Germany.svg",
    number: "+49",
    value: "DE",
    timezones: ["Europe/Berlin", "Europe/Busingen"],
    position: { lat: 51, lng: 9 },
    latlng: [51, 9],
    capital: "Berlin"
  },
  {
    name: "Ghana",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Ghana.svg",
    number: "+233",
    value: "GH",
    timezones: ["Africa/Accra"],
    position: { lat: 8, lng: -2 },
    latlng: [8, -2],
    capital: "Accra"
  },
  {
    name: "Gibraltar",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/0/02/Flag_of_Gibraltar.svg",
    number: "+350",
    value: "GI",
    timezones: ["Europe/Gibraltar"],
    position: { lat: 36.13333333, lng: -5.35 },
    latlng: [36.13333333, -5.35],
    capital: "Gibraltar"
  },
  {
    name: "Greece",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Greece.svg",
    number: "+30",
    value: "GR",
    timezones: ["Europe/Athens"],
    position: { lat: 39, lng: 22 },
    latlng: [39, 22],
    capital: "Athens"
  },
  {
    name: "Greenland",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/0/09/Flag_of_Greenland.svg",
    number: "+299",
    value: "GL",
    timezones: [
      "America/Godthab",
      "America/Danmarkshavn",
      "America/Scoresbysund",
      "America/Thule"
    ],
    position: { lat: 72, lng: -40 },
    latlng: [72, -40],
    capital: "Nuuk"
  },
  {
    name: "Grenada",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Grenada.svg",
    number: "+1473",
    value: "GD",
    timezones: ["America/Grenada"],
    position: { lat: 12.11666666, lng: -61.66666666 },
    latlng: [12.11666666, -61.66666666],
    capital: "St. George's"
  },
  {
    name: "Guadeloupe",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/e/e7/Unofficial_flag_of_Guadeloupe_%28local%29.svg",
    number: "+590",
    value: "GP",
    timezones: ["America/Guadeloupe"],
    position: { lat: 16.25, lng: -61.583333 },
    latlng: [16.25, -61.583333],
    capital: "Basse-Terre"
  },
  {
    name: "Guam",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/0/07/Flag_of_Guam.svg",
    number: "+1671",
    value: "GU",
    timezones: ["Pacific/Guam"],
    position: { lat: 13.46666666, lng: 144.78333333 },
    latlng: [13.46666666, 144.78333333],
    capital: "Hagåtña"
  },
  {
    name: "Guatemala",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/e/ec/Flag_of_Guatemala.svg",
    number: "+502",
    value: "GT",
    timezones: ["America/Guatemala"],
    position: { lat: 15.5, lng: -90.25 },
    latlng: [15.5, -90.25],
    capital: "Guatemala City"
  },
  {
    name: "Guernsey",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Guernsey.svg",
    number: "+44",
    value: "GG",
    timezones: ["Europe/Guernsey"],
    position: { lat: 49.46666666, lng: -2.58333333 },
    latlng: [49.46666666, -2.58333333],
    capital: "St. Peter Port"
  },
  {
    name: "Guinea",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_Guinea.svg",
    number: "+224",
    value: "GN",
    timezones: ["Africa/Conakry"],
    position: { lat: 11, lng: -10 },
    latlng: [11, -10],
    capital: "Conakry"
  },
  {
    name: "Guinea-Bissau",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Guinea-Bissau.svg",
    number: "+245",
    value: "GW",
    timezones: ["Africa/Bissau"],
    position: { lat: 12, lng: -15 },
    latlng: [12, -15],
    capital: "Bissau"
  },
  {
    name: "Guyana",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_Guyana.svg",
    number: "+592",
    value: "GY",
    timezones: ["America/Guyana"],
    position: { lat: 5, lng: -59 },
    latlng: [5, -59],
    capital: "Georgetown"
  },
  {
    name: "Haiti",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Haiti.svg",
    number: "+509",
    value: "HT",
    timezones: ["America/Port-au-Prince"],
    position: { lat: 19, lng: -72.41666666 },
    latlng: [19, -72.41666666],
    capital: "Port-au-Prince"
  },
  {
    name: "Heard Island and McDonald Islands",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/b/bb/Proposed_flag_of_Antarctica_%28Graham_Bartram%29.svg",
    number: "+672",
    value: "HM"
  },
  {
    name: "Holy See (Vatican City State)",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_the_Vatican_City.svg",
    number: "+379",
    value: "VA",
    timezones: ["Europe/Vatican"],
    position: { lat: 41.9, lng: 12.45 },
    latlng: [41.9, 12.45],
    capital: "Vatican City"
  },
  {
    name: "Honduras",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/c/ca/Naval_Ensign_of_Honduras.svg",
    number: "+504",
    value: "HN",
    timezones: ["America/Tegucigalpa"],
    position: { lat: 15, lng: -86.5 },
    latlng: [15, -86.5],
    capital: "Tegucigalpa"
  },
  {
    name: "Hong Kong",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/5/5b/Flag_of_Hong_Kong.svg",
    number: "+852",
    value: "HK",
    timezones: ["Asia/Hong_Kong"],
    position: { lat: 22.267, lng: 114.188 },
    latlng: [22.267, 114.188],
    capital: "City of Victoria"
  },
  {
    name: "Hungary",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/c/c1/Flag_of_Hungary.svg",
    number: "+36",
    value: "HU",
    timezones: ["Europe/Budapest"],
    position: { lat: 47, lng: 20 },
    latlng: [47, 20],
    capital: "Budapest"
  },
  {
    name: "Iceland",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Iceland.svg",
    number: "+354",
    value: "IS",
    timezones: ["Atlantic/Reykjavik"],
    position: { lat: 65, lng: -18 },
    latlng: [65, -18],
    capital: "Reykjavik"
  },
  {
    name: "India",
    flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
    number: "+91",
    value: "IN",
    timezones: ["Asia/Kolkata"],
    position: { lat: 20, lng: 77 },
    latlng: [20, 77],
    capital: "New Delhi"
  },
  */{
        name: "Indonesia",
        flag:
            "https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Indonesia.svg",
        number: "+62",
        value: "ID",
        timezones: [
            "Asia/Jakarta",
            "Asia/Pontianak",
            "Asia/Makassar",
            "Asia/Jayapura"
        ],
        position: {lat: -5, lng: 120},
        latlng: [-5, 120],
        capital: "Jakarta"
    },
    /*
  {
    name: "Iran",
    flag:
      " https://upload.wikimedia.org/wikipedia/commons/c/ca/Flag_of_Iran.svg",
    number: "+98",
    value: "IR",
    timezones: ["Asia/Tehran"],
    position: { lat: 32, lng: 53 },
    latlng: [32, 53],
    capital: "Tehran"
  },
  {
    name: "Iraq",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Iraq.svg",
    number: "+964",
    value: "IQ",
    timezones: ["Asia/Baghdad"],
    position: { lat: 33, lng: 44 },
    latlng: [33, 44],
    capital: "Baghdad"
  },
  {
    name: "Ireland",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/4/45/Flag_of_Ireland.svg",
    number: "+353",
    value: "IE",
    timezones: ["Europe/Dublin"],
    position: { lat: 53, lng: -8 },
    latlng: [53, -8],
    capital: "Dublin"
  },
  {
    name: "Isle of Man",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_the_Isle_of_Man.svg",
    number: "+44",
    value: "IM",
    timezones: ["Europe/Isle_of_Man"],
    position: { lat: 54.25, lng: -4.5 },
    latlng: [54.25, -4.5],
    capital: "Douglas"
  },
  {
    name: "Israel",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    number: "+972",
    value: "IL",
    timezones: ["Asia/Jerusalem"],
    position: { lat: 31.47, lng: 35.13 },
    latlng: [31.47, 35.13],
    capital: "Jerusalem"
  },
  {
    name: "Italy",
    flag: "https://upload.wikimedia.org/wikipedia/en/0/03/Flag_of_Italy.svg",
    number: "+39",
    value: "IT",
    timezones: ["Europe/Rome"],
    position: { lat: 42.83333333, lng: 12.83333333 },
    latlng: [42.83333333, 12.83333333],
    capital: "Rome"
  },
  {
    name: "Jamaica",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Jamaica.svg",
    number: "+1876",
    value: "JM",
    timezones: ["America/Jamaica"],
    position: { lat: 18.25, lng: -77.5 },
    latlng: [18.25, -77.5],
    capital: "Kingston"
  },
  */
  {
    name: "Japan",
    flag: "https://upload.wikimedia.org/wikipedia/en/9/9e/Flag_of_Japan.svg",
    number: "+81",
    value: "JP",
    timezones: ["Asia/Tokyo"],
    position: { lat: 36, lng: 138 },
    latlng: [36, 138],
    capital: "Tokyo"
  },
    /*
  {
    name: "Jersey",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/1/1c/Flag_of_Jersey.svg",
    number: "+44",
    value: "JE",
    timezones: ["Europe/Jersey"],
    position: { lat: 49.25, lng: -2.16666666 },
    latlng: [49.25, -2.16666666],
    capital: "Saint Helier"
  },
  {
    name: "Jordan",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/c/c0/Flag_of_Jordan.svg",
    number: "+962",
    value: "JO",
    timezones: ["Asia/Amman"],
    position: { lat: 31, lng: 36 },
    latlng: [31, 36],
    capital: "Amman"
  },
  {
    name: "Kazakhstan",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kazakhstan.svg",
    number: "+7",
    value: "KZ",
    timezones: [
      "Asia/Almaty",
      "Asia/Qyzylorda",
      "Asia/Aqtobe",
      "Asia/Aqtau",
      "Asia/Oral"
    ],
    position: { lat: 48, lng: 68 },
    latlng: [48, 68],
    capital: "Astana"
  },
  {
    name: "Kenya",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Kenya.svg",
    number: "+254",
    value: "KE",
    timezones: ["Africa/Nairobi"],
    position: { lat: 1, lng: 38 },
    latlng: [1, 38],
    capital: "Nairobi"
  },
  {
    name: "Kiribati",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kiribati.svg",
    number: "+686",
    value: "KI",
    timezones: ["Pacific/Tarawa", "Pacific/Enderbury", "Pacific/Kiritimati"],
    position: { lat: 1.41666666, lng: 173 },
    latlng: [1.41666666, 173],
    capital: "South Tarawa"
  },
  {
    name: "Kuwait",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/a/aa/Flag_of_Kuwait.svg",
    number: "+965",
    value: "KW",
    timezones: ["Asia/Kuwait"],
    position: { lat: 29.5, lng: 45.75 },
    latlng: [29.5, 45.75],
    capital: "Kuwait City"
  },
  {
    name: "Kyrgyzstan",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/c/c7/Flag_of_Kyrgyzstan.svg",
    number: "+996",
    value: "KG",
    timezones: ["Asia/Bishkek"],
    position: { lat: 41, lng: 75 },
    latlng: [41, 75],
    capital: "Bishkek"
  },
  */{
        name: "Lao PDR",
        flag:
            "https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Laos.svg",
        number: "+856",
        value: "LA",
        timezones: ["Asia/Vientiane"],
        position: {lat: 18, lng: 105},
        latlng: [18, 105],
        capital: "Vientiane"
    },
    /*
  {
    name: "Latvia",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Latvia.svg",
    number: "+371",
    value: "LV",
    timezones: ["Europe/Riga"],
    position: { lat: 57, lng: 25 },
    latlng: [57, 25],
    capital: "Riga"
  },
  {
    name: "Lebanon",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/5/59/Flag_of_Lebanon.svg",
    number: "+961",
    value: "LB",
    timezones: ["Asia/Beirut"],
    position: { lat: 33.83333333, lng: 35.83333333 },
    latlng: [33.83333333, 35.83333333],
    capital: "Beirut"
  },
  {
    name: "Lesotho",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/4/4a/Flag_of_Lesotho.svg",
    number: "+266",
    value: "LS",
    timezones: ["Africa/Maseru"],
    position: { lat: -29.5, lng: 28.5 },
    latlng: [-29.5, 28.5],
    capital: "Maseru"
  },
  {
    name: "Liberia",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/b/b8/Flag_of_Liberia.svg",
    number: "+231",
    value: "LR",
    timezones: ["Africa/Monrovia"],
    position: { lat: 6.5, lng: -9.5 },
    latlng: [6.5, -9.5],
    capital: "Monrovia"
  },
  {
    name: "Liechtenstein",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/4/47/Flag_of_Liechtenstein.svg",
    number: "+423",
    value: "LI",
    timezones: ["Europe/Vaduz"],
    position: { lat: 47.26666666, lng: 9.53333333 },
    latlng: [47.26666666, 9.53333333],
    capital: "Vaduz"
  },
  {
    name: "Lithuania",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Lithuania.svg",
    number: "+370",
    value: "LT",
    timezones: ["Europe/Vilnius"],
    position: { lat: 56, lng: 24 },
    latlng: [56, 24],
    capital: "Vilnius"
  },
  {
    name: "Luxembourg",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/d/da/Flag_of_Luxembourg.svg",
    number: "+352",
    value: "LU",
    timezones: ["Europe/Luxembourg"],
    position: { lat: 49.75, lng: 6.16666666 },
    latlng: [49.75, 6.16666666],
    capital: "Luxembourg"
  },
  {
    name: "Macao",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/6/63/Flag_of_Macau.svg",
    number: "+853",
    value: "MO",
    timezones: ["Asia/Macau"],
    position: { lat: 22.16666666, lng: 113.55 },
    latlng: [22.16666666, 113.55],
    capital: null
  },
  {
    name: "Madagascar",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Madagascar.svg",
    number: "+261",
    value: "MG",
    timezones: ["Indian/Antananarivo"],
    position: { lat: -20, lng: 47 },
    latlng: [-20, 47],
    capital: "Antananarivo"
  },
  {
    name: "Malawi",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/d/d1/Flag_of_Malawi.svg",
    number: "+265",
    value: "MW",
    timezones: ["Africa/Blantyre"],
    position: { lat: -13.5, lng: 34 },
    latlng: [-13.5, 34],
    capital: "Lilongwe"
  },
  */{
    name: "Malaysia",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/6/66/Flag_of_Malaysia.svg",
    number: "+60",
    value: "MY",
    timezones: ["Asia/Kuala_Lumpur", "Asia/Kuching"],
    position: { lat: 2.5, lng: 112.5 },
    latlng: [2.5, 112.5],
    capital: "Kuala Lumpur"
  },
    /*
  {
    name: "Maldives",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Maldives.svg",
    number: "+960",
    value: "MV",
    timezones: ["Indian/Maldives"],
    position: { lat: 3.25, lng: 73 },
    latlng: [3.25, 73],
    capital: "Malé"
  },
  {
    name: "Mali",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_Mali.svg",
    number: "+223",
    value: "ML",
    timezones: ["Africa/Bamako"],
    position: { lat: 17, lng: -4 },
    latlng: [17, -4],
    capital: "Bamako"
  },
  {
    name: "Malta",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Malta.svg",
    number: "+356",
    value: "MT",
    timezones: ["Europe/Malta"],
    position: { lat: 35.83333333, lng: 14.58333333 },
    latlng: [35.83333333, 14.58333333],
    capital: "Valletta"
  },
  {
    name: "Marshall Islands",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/2/2e/Flag_of_the_Marshall_Islands.svg",
    number: "+692",
    value: "MH",
    timezones: ["Pacific/Majuro", "Pacific/Kwajalein"],
    position: { lat: 9, lng: 168 },
    latlng: [9, 168],
    capital: "Majuro"
  },
  {
    name: "Martinique",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/e/e7/Unofficial_flag_of_Guadeloupe_%28local%29.svg",
    number: "+596",
    value: "MQ",
    timezones: ["America/Martinique"],
    position: { lat: 14.666667, lng: -61 },
    latlng: [14.666667, -61],
    capital: "Fort-de-France"
  },
  {
    name: "Mauritania",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/4/43/Flag_of_Mauritania.svg",
    number: "+222",
    value: "MR",
    timezones: ["Africa/Nouakchott"],
    position: { lat: 20, lng: -12 },
    latlng: [20, -12],
    capital: "Nouakchott"
  },
  {
    name: "Mauritius",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Mauritius.svg",
    number: "+230",
    value: "MU",
    timezones: ["Indian/Mauritius"],
    position: { lat: -20.28333333, lng: 57.55 },
    latlng: [-20.28333333, 57.55],
    capital: "Port Louis"
  },
  {
    name: "Mayotte",
    flag: "https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg",
    number: "+262",
    value: "YT",
    timezones: ["Indian/Mayotte"],
    position: { lat: -12.83333333, lng: 45.16666666 },
    latlng: [-12.83333333, 45.16666666],
    capital: "Mamoudzou"
  },
  {
    name: "Mexico",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Mexico.svg",
    number: "+52",
    value: "MX",
    timezones: [
      "America/Mexico_City",
      "America/Cancun",
      "America/Merida",
      "America/Monterrey",
      "America/Matamoros",
      "America/Mazatlan",
      "America/Chihuahua",
      "America/Ojinaga",
      "America/Hermosillo",
      "America/Tijuana",
      "America/Bahia_Banderas"
    ],
    position: { lat: 23, lng: -102 },
    latlng: [23, -102],
    capital: "Mexico City"
  },
  {
    name: "Monaco",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/e/ea/Flag_of_Monaco.svg",
    number: "+377",
    value: "MC",
    timezones: ["Europe/Monaco"],
    position: { lat: 43.73333333, lng: 7.4 },
    latlng: [43.73333333, 7.4],
    capital: "Monaco"
  },
  {
    name: "Mongolia",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Mongolia.svg",
    number: "+976",
    value: "MN",
    timezones: ["Asia/Ulaanbaatar", "Asia/Hovd", "Asia/Choibalsan"],
    position: { lat: 46, lng: 105 },
    latlng: [46, 105],
    capital: "Ulan Bator"
  },
  {
    name: "Montenegro",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Montenegro.svg",
    number: "+382",
    value: "ME",
    timezones: ["Europe/Podgorica"],
    position: { lat: 42.5, lng: 19.3 },
    latlng: [42.5, 19.3],
    capital: "Podgorica"
  },
  {
    name: "Montserrat",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Montserrat.svg",
    number: "+1664",
    value: "MS",
    timezones: ["America/Montserrat"],
    position: { lat: 16.75, lng: -62.2 },
    latlng: [16.75, -62.2],
    capital: "Plymouth"
  },
  {
    name: "Morocco",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Morocco.svg",
    number: "+212",
    value: "MA",
    timezones: ["Africa/Casablanca"],
    position: { lat: 32, lng: -5 },
    latlng: [32, -5],
    capital: "Rabat"
  },
  {
    name: "Mozambique",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Mozambique.svg",
    number: "+258",
    value: "MZ",
    timezones: ["Africa/Maputo"],
    position: { lat: -18.25, lng: 35 },
    latlng: [-18.25, 35],
    capital: "Maputo"
  },
  */
    {
        name: "Myanmar",
        flag:
            "https://upload.wikimedia.org/wikipedia/commons/8/8c/Flag_of_Myanmar.svg",
        number: "+95",
        value: "MM",
        timezones: ["Asia/Rangoon"],
        position: {lat: 22, lng: 98},
        latlng: [22, 98],
        capital: "Naypyidaw"
    },
    /*
  {
    name: "Namibia",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Namibia.svg",
    number: "+264",
    value: "NA",
    timezones: ["Africa/Windhoek"],
    position: { lat: -22, lng: 17 },
    latlng: [-22, 17],
    capital: "Windhoek"
  },
  {
    name: "Nauru",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/3/30/Flag_of_Nauru.svg",
    number: "+674",
    value: "NR",
    timezones: ["Pacific/Nauru"],
    position: { lat: -0.53333333, lng: 166.91666666 },
    latlng: [-0.53333333, 166.91666666],
    capital: "Yaren"
  },
  {
    name: "Nepal",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/9/9b/Flag_of_Nepal.svg",
    number: "+977",
    value: "NP",
    timezones: ["Asia/Kathmandu"],
    position: { lat: 28, lng: 84 },
    latlng: [28, 84],
    capital: "Kathmandu"
  },
  {
    name: "Netherlands",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_the_Netherlands.svg",
    number: "+31",
    value: "NL",
    timezones: ["Europe/Amsterdam"],
    position: { lat: 52.5, lng: 5.75 },
    latlng: [52.5, 5.75],
    capital: "Amsterdam"
  },
  {
    name: "New Caledonia",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/2/26/Flags_of_New_Caledonia.svg",
    number: "+687",
    value: "NC",
    timezones: ["Pacific/Noumea"],
    position: { lat: -21.5, lng: 165.5 },
    latlng: [-21.5, 165.5],
    capital: "Nouméa"
  },
  {
    name: "New Zealand",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/3/3e/Flag_of_New_Zealand.svg",
    number: "+64",
    value: "NZ",
    timezones: ["Pacific/Auckland", "Pacific/Chatham"],
    position: { lat: -41, lng: 174 },
    latlng: [-41, 174],
    capital: "Wellington"
  },
  {
    name: "Nicaragua",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Nicaragua.svg",
    number: "+505",
    value: "NI",
    timezones: ["America/Managua"],
    position: { lat: 13, lng: -85 },
    latlng: [13, -85],
    capital: "Managua"
  },
  {
    name: "Niger",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/f/f4/Flag_of_Niger.svg",
    number: "+227",
    value: "NE",
    timezones: ["Africa/Niamey"],
    position: { lat: 16, lng: 8 },
    latlng: [16, 8],
    capital: "Niamey"
  },
  {
    name: "Nigeria",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_Nigeria.svg",
    number: "+234",
    value: "NG",
    timezones: ["Africa/Lagos"],
    position: { lat: 10, lng: 8 },
    latlng: [10, 8],
    capital: "Abuja"
  },
  {
    name: "Niue",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Niue.svg",
    number: "+683",
    value: "NU",
    timezones: ["Pacific/Niue"],
    position: { lat: -19.03333333, lng: -169.86666666 },
    latlng: [-19.03333333, -169.86666666],
    capital: "Alofi"
  },
  {
    name: "Norfolk Island",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Norfolk_Island.svg",
    number: "+672",
    value: "NF",
    timezones: ["Pacific/Norfolk"],
    position: { lat: -29.03333333, lng: 167.95 },
    latlng: [-29.03333333, 167.95],
    capital: "Kingston"
  },
  {
    name: "Northern Mariana Islands",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/e/e0/Flag_of_the_Northern_Mariana_Islands.svg",
    number: "+1670",
    value: "MP",
    timezones: ["Pacific/Saipan"],
    position: { lat: 15.2, lng: 145.75 },
    latlng: [15.2, 145.75],
    capital: "Saipan"
  },
  {
    name: "Norway",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg",
    number: "+47",
    value: "NO",
    timezones: ["Europe/Oslo"],
    position: { lat: 62, lng: 10 },
    latlng: [62, 10],
    capital: "Oslo"
  },
  {
    name: "Oman",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Oman.svg",
    number: "+968",
    value: "OM",
    timezones: ["Asia/Muscat"],
    position: { lat: 21, lng: 57 },
    latlng: [21, 57],
    capital: "Muscat"
  },
  {
    name: "Pakistan",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/3/32/Flag_of_Pakistan.svg",
    number: "+92",
    value: "PK",
    timezones: ["Asia/Karachi"],
    position: { lat: 30, lng: 70 },
    latlng: [30, 70],
    capital: "Islamabad"
  },
  {
    name: "Palau",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Palau.svg",
    number: "+680",
    value: "PW",
    timezones: ["Pacific/Palau"],
    position: { lat: 7.5, lng: 134.5 },
    latlng: [7.5, 134.5],
    capital: "Ngerulmud"
  },
  {
    name: "Panama",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/a/ab/Flag_of_Panama.svg",
    number: "+507",
    value: "PA",
    timezones: ["America/Panama"],
    position: { lat: 9, lng: -80 },
    latlng: [9, -80],
    capital: "Panama City"
  },
  {
    name: "Papua New Guinea",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/e/e3/Flag_of_Papua_New_Guinea.svg",
    number: "+675",
    value: "PG",
    timezones: ["Pacific/Port_Moresby", "Pacific/Bougainville"],
    position: { lat: -6, lng: 147 },
    latlng: [-6, 147],
    capital: "Port Moresby"
  },
  {
    name: "Paraguay",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Paraguay.svg",
    number: "+595",
    value: "PY",
    timezones: ["America/Asuncion"],
    position: { lat: -23, lng: -58 },
    latlng: [-23, -58],
    capital: "Asunción"
  },
  {
    name: "Peru",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/c/cf/Flag_of_Peru.svg",
    number: "+51",
    value: "PE",
    timezones: ["America/Lima"],
    position: { lat: -10, lng: -76 },
    latlng: [-10, -76],
    capital: "Lima"
  },
  */{
    name: "Philippines",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_the_Philippines.svg",
    number: "+63",
    value: "PH",
    timezones: ["Asia/Manila"],
    position: { lat: 13, lng: 122 },
    latlng: [13, 122],
    capital: "Manila"
  },
    /*
  {
    name: "Pitcairn",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_the_Pitcairn_Islands.svg",
    number: "+870",
    value: "PN",
    timezones: ["Pacific/Pitcairn"],
    position: { lat: -25.06666666, lng: -130.1 },
    latlng: [-25.06666666, -130.1],
    capital: "Adamstown"
  },
  {
    name: "Poland",
    flag: "https://upload.wikimedia.org/wikipedia/en/1/12/Flag_of_Poland.svg",
    number: "+48",
    value: "PL",
    timezones: ["Europe/Warsaw"],
    position: { lat: 52, lng: 20 },
    latlng: [52, 20],
    capital: "Warsaw"
  },
  {
    name: "Portugal",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Portugal.svg",
    number: "+351",
    value: "PT",
    timezones: ["Europe/Lisbon", "Atlantic/Madeira", "Atlantic/Azores"],
    position: { lat: 39.5, lng: -8 },
    latlng: [39.5, -8],
    capital: "Lisbon"
  },
  {
    name: "Puerto Rico",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/2/28/Flag_of_Puerto_Rico.svg",
    number: "+1939",
    value: "PR",
    timezones: ["America/Puerto_Rico"],
    position: { lat: 18.25, lng: -66.5 },
    latlng: [18.25, -66.5],
    capital: "San Juan"
  },
  {
    name: "Qatar",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Qatar.svg",
    number: "+974",
    value: "QA",
    timezones: ["Asia/Qatar"],
    position: { lat: 25.5, lng: 51.25 },
    latlng: [25.5, 51.25],
    capital: "Doha"
  },
  {
    name: "Réunion",
    flag: "https://upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg",
    number: "+262",
    value: "RE",
    timezones: ["Indian/Reunion"],
    position: { lat: -21.15, lng: 55.5 },
    latlng: [-21.15, 55.5],
    capital: "Saint-Denis"
  },
  {
    name: "Romania",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Romania.svg",
    number: "+40",
    value: "RO",
    timezones: ["Europe/Bucharest"],
    position: { lat: 46, lng: 25 },
    latlng: [46, 25],
    capital: "Bucharest"
  },
  {
    name: "Rwanda",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Rwanda.svg",
    number: "+250",
    value: "RW",
    timezones: ["Africa/Kigali"],
    position: { lat: -2, lng: 30 },
    latlng: [-2, 30],
    capital: "Kigali"
  },
  {
    name: "Saint Kitts and Nevis",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Saint_Kitts_and_Nevis.svg",
    number: "+1869",
    value: "KN",
    timezones: ["America/St_Kitts"],
    position: { lat: 17.33333333, lng: -62.75 },
    latlng: [17.33333333, -62.75],
    capital: "Basseterre"
  },
  {
    name: "Saint Lucia",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Saint_Lucia.svg",
    number: "+1758",
    value: "LC",
    timezones: ["America/St_Lucia"],
    position: { lat: 13.88333333, lng: -60.96666666 },
    latlng: [13.88333333, -60.96666666],
    capital: "Castries"
  },
  {
    name: "Saint Pierre and Miquelon",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_Saint-Pierre_and_Miquelon.svg",
    number: "+508",
    value: "PM",
    timezones: ["America/Miquelon"],
    position: { lat: 46.83333333, lng: -56.33333333 },
    latlng: [46.83333333, -56.33333333],
    capital: "Saint-Pierre"
  },
  {
    name: "Saint Vincent and the Grenadines",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/6/6d/Flag_of_Saint_Vincent_and_the_Grenadines.svg",
    number: "+1784",
    value: "VC",
    timezones: ["America/St_Vincent"],
    position: { lat: 13.25, lng: -61.2 },
    latlng: [13.25, -61.2],
    capital: "Kingstown"
  },
  {
    name: "Samoa",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Samoa.svg",
    number: "+685",
    value: "WS",
    timezones: ["Pacific/Apia"],
    position: { lat: -13.58333333, lng: -172.33333333 },
    latlng: [-13.58333333, -172.33333333],
    capital: "Apia"
  },
  {
    name: "San Marino",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/b/b1/Flag_of_San_Marino.svg",
    number: "+378",
    value: "SM",
    timezones: ["Europe/San_Marino"],
    position: { lat: 43.76666666, lng: 12.41666666 },
    latlng: [43.76666666, 12.41666666],
    capital: "City of San Marino"
  },
  {
    name: "Sao Tome and Principe",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_Sao_Tome_and_Principe.svg",
    number: "+239",
    value: "ST",
    timezones: ["Africa/Sao_Tome"],
    position: { lat: 1, lng: 7 },
    latlng: [1, 7],
    capital: "São Tomé"
  },
  {
    name: "Saudi Arabia",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg",
    number: "+966",
    value: "SA",
    timezones: ["Asia/Riyadh"],
    position: { lat: 25, lng: 45 },
    latlng: [25, 45],
    capital: "Riyadh"
  },
  {
    name: "Senegal",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/f/fd/Flag_of_Senegal.svg",
    number: "+221",
    value: "SN",
    timezones: ["Africa/Dakar"],
    position: { lat: 14, lng: -14 },
    latlng: [14, -14],
    capital: "Dakar"
  },
  {
    name: "Serbia",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/f/ff/Flag_of_Serbia.svg",
    number: "+381",
    value: "RS",
    timezones: ["Europe/Belgrade"],
    position: { lat: 44, lng: 21 },
    latlng: [44, 21],
    capital: "Belgrade"
  },
  {
    name: "Seychelles",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Seychelles.svg",
    number: "+248",
    value: "SC",
    timezones: ["Indian/Mahe"],
    position: { lat: -4.58333333, lng: 55.66666666 },
    latlng: [-4.58333333, 55.66666666],
    capital: "Victoria"
  },
  {
    name: "Sierra Leone",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Sierra_Leone.svg",
    number: "+232",
    value: "SL",
    timezones: ["Africa/Freetown"],
    position: { lat: 8.5, lng: -11.5 },
    latlng: [8.5, -11.5],
    capital: "Freetown"
  },
  */{
    name: "Singapore",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Singapore.svg",
    number: "+65",
    value: "SG",
    timezones: ["Asia/Singapore"],
    position: { lat: 1.36666666, lng: 103.8 },
    latlng: [1.36666666, 103.8],
    capital: "Singapore"
  },
    /*
  {
    name: "Slovakia",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/e/e6/Flag_of_Slovakia.svg",
    number: "+421",
    value: "SK",
    timezones: ["Europe/Bratislava"],
    position: { lat: 48.66666666, lng: 19.5 },
    latlng: [48.66666666, 19.5],
    capital: "Bratislava"
  },
  {
    name: "Slovenia",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/f/f0/Flag_of_Slovenia.svg",
    number: "+386",
    value: "SI",
    timezones: ["Europe/Ljubljana"],
    position: { lat: 46.11666666, lng: 14.81666666 },
    latlng: [46.11666666, 14.81666666],
    capital: "Ljubljana"
  },
  {
    name: "Solomon Islands",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_the_Solomon_Islands.svg",
    number: "+677",
    value: "SB",
    timezones: ["Pacific/Guadalcanal"],
    position: { lat: -8, lng: 159 },
    latlng: [-8, 159],
    capital: "Honiara"
  },
  {
    name: "Somalia",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_Somalia.svg",
    number: "+252",
    value: "SO",
    timezones: ["Africa/Mogadishu"],
    position: { lat: 10, lng: 49 },
    latlng: [10, 49],
    capital: "Mogadishu"
  },
  {
    name: "South Africa",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/a/af/Flag_of_South_Africa.svg",
    number: "+27",
    value: "ZA",
    timezones: ["Africa/Johannesburg"],
    position: { lat: -29, lng: 24 },
    latlng: [-29, 24],
    capital: "Pretoria"
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_South_Georgia_and_the_South_Sandwich_Islands.svg",
    number: "+500",
    value: "GS",
    timezones: ["Atlantic/South_Georgia"],
    position: { lat: -54.5, lng: -37 },
    latlng: [-54.5, -37],
    capital: "King Edward Point"
  },
  {
    name: "Spain",
    flag: "https://upload.wikimedia.org/wikipedia/en/9/9a/Flag_of_Spain.svg",
    number: "+34",
    value: "ES",
    timezones: ["Europe/Madrid", "Africa/Ceuta", "Atlantic/Canary"],
    position: { lat: 40, lng: -4 },
    latlng: [40, -4],
    capital: "Madrid"
  },
  {
    name: "Sri Lanka",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg",
    number: "+94",
    value: "LK",
    timezones: ["Asia/Colombo"],
    position: { lat: 7, lng: 81 },
    latlng: [7, 81],
    capital: "Colombo"
  },
  {
    name: "Sudan",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Sudan.svg",
    number: "+249",
    value: "SD",
    timezones: ["Africa/Khartoum"],
    position: { lat: 15, lng: 30 },
    latlng: [15, 30],
    capital: "Khartoum"
  },
  {
    name: "Suriname",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/6/60/Flag_of_Suriname.svg",
    number: "+597",
    value: "SR",
    timezones: ["America/Paramaribo"],
    position: { lat: 4, lng: -56 },
    latlng: [4, -56],
    capital: "Paramaribo"
  },
  {
    name: "Eswatini",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Swaziland_1894.svg",
    number: "+268",
    value: "SZ",
    timezones: ["Africa/Mbabane"],
    position: { lat: -26.5, lng: 31.5 },
    latlng: [-26.5, 31.5],
    capital: "Lobamba"
  },
  {
    name: "Sweden",
    flag: "https://upload.wikimedia.org/wikipedia/en/4/4c/Flag_of_Sweden.svg",
    number: "+46",
    value: "SE",
    timezones: ["Europe/Stockholm"],
    position: { lat: 62, lng: 15 },
    latlng: [62, 15],
    capital: "Stockholm"
  },
  {
    name: "Switzerland",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/0/08/Flag_of_Switzerland_%28Pantone%29.svg",
    number: "+41",
    value: "CH",
    timezones: ["Europe/Zurich"],
    position: { lat: 47, lng: 8 },
    latlng: [47, 8],
    capital: "Bern"
  },
  {
    name: "Syrian Arab Republic",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/5/53/Flag_of_Syria.svg",
    number: "+963",
    value: "SY",
    timezones: ["Asia/Damascus"],
    position: { lat: 35, lng: 38 },
    latlng: [35, 38],
    capital: "Damascus"
  },
  {
    name: "Taiwan",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/7/72/Flag_of_the_Republic_of_China.svg",
    number: "+886",
    value: "TW",
    timezones: ["Asia/Taipei"],
    position: { lat: 23.5, lng: 121 },
    latlng: [23.5, 121],
    capital: "Taipei"
  },
  {
    name: "Tajikistan",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Tajikistan.svg",
    number: "+992",
    value: "TJ",
    timezones: ["Asia/Dushanbe"],
    position: { lat: 39, lng: 71 },
    latlng: [39, 71],
    capital: "Dushanbe"
  },
  */
    {
        name: "Thailand",
        flag:
            "https://upload.wikimedia.org/wikipedia/commons/a/a9/Flag_of_Thailand.svg",
        number: "+66",
        value: "TH",
        timezones: ["Asia/Bangkok"],
        position: {lat: 15, lng: 100},
        latlng: [15, 100],
        capital: "Bangkok"
    },
    /*
  {
    name: "Timor-Leste",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/2/26/Flag_of_East_Timor.svg",
    number: "+670",
    value: "TL",
    timezones: ["Asia/Dili"],
    position: { lat: -8.83333333, lng: 125.91666666 },
    latlng: [-8.83333333, 125.91666666],
    capital: "Dili"
  },
  {
    name: "Togo",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/6/68/Flag_of_Togo.svg",
    number: "+228",
    value: "TG",
    timezones: ["Africa/Lome"],
    position: { lat: 8, lng: 1.16666666 },
    latlng: [8, 1.16666666],
    capital: "Lomé"
  },
  {
    name: "Tokelau",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/8/8e/Flag_of_Tokelau.svg",
    number: "+690",
    value: "TK",
    timezones: ["Pacific/Fakaofo"],
    position: { lat: -9, lng: -172 },
    latlng: [-9, -172],
    capital: "Fakaofo"
  },
  {
    name: "Tonga",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Tonga.svg",
    number: "+676",
    value: "TO",
    timezones: ["Pacific/Tongatapu"],
    position: { lat: -20, lng: -175 },
    latlng: [-20, -175],
    capital: "Nuku'alofa"
  },
  {
    name: "Trinidad and Tobago",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Trinidad_and_Tobago.svg",
    number: "+1868",
    value: "TT",
    timezones: ["America/Port_of_Spain"],
    position: { lat: 11, lng: -61 },
    latlng: [11, -61],
    capital: "Port of Spain"
  },
  {
    name: "Tunisia",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Tunisia.svg",
    number: "+216",
    value: "TN",
    timezones: ["Africa/Tunis"],
    position: { lat: 34, lng: 9 },
    latlng: [34, 9],
    capital: "Tunis"
  },
  {
    name: "Turkey",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Turkey.svg",
    number: "+90",
    value: "TR",
    timezones: ["Europe/Istanbul"],
    position: { lat: 39, lng: 35 },
    latlng: [39, 35],
    capital: "Ankara"
  },
  {
    name: "Turkmenistan",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Turkmenistan.svg",
    number: "+993",
    value: "TM",
    timezones: ["Asia/Ashgabat"],
    position: { lat: 40, lng: 60 },
    latlng: [40, 60],
    capital: "Ashgabat"
  },
  {
    name: "Turks and Caicos Islands",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_the_Turks_and_Caicos_Islands.svg",
    number: "+1649",
    value: "TC",
    timezones: ["America/Grand_Turk"],
    position: { lat: 21.75, lng: -71.58333333 },
    latlng: [21.75, -71.58333333],
    capital: "Cockburn Town"
  },
  {
    name: "Tuvalu",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Tuvalu.svg",
    number: "+688",
    value: "TV",
    timezones: ["Pacific/Funafuti"],
    position: { lat: -8, lng: 178 },
    latlng: [-8, 178],
    capital: "Funafuti"
  },
  {
    name: "Uganda",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/4/4e/Flag_of_Uganda.svg",
    number: "+256",
    value: "UG",
    timezones: ["Africa/Kampala"],
    position: { lat: 1, lng: 32 },
    latlng: [1, 32],
    capital: "Kampala"
  },
  {
    name: "Ukraine",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Ukraine.svg",
    number: "+380",
    value: "UA",
    timezones: ["Europe/Kiev", "Europe/Uzhgorod", "Europe/Zaporozhye"],
    position: { lat: 49, lng: 32 },
    latlng: [49, 32],
    capital: "Kiev"
  },
  {
    name: "United Arab Emirates",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_United_Arab_Emirates.svg",
    number: "+971",
    value: "AE",
    timezones: ["Asia/Dubai"],
    position: { lat: 24, lng: 54 },
    latlng: [24, 54],
    capital: "Abu Dhabi"
  },
  {
    name: "United Kingdom",
    flag:
      "https://upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg",
    number: "+44",
    value: "GB",
    timezones: ["Europe/London"],
    position: { lat: 54, lng: -2 },
    latlng: [54, -2],
    capital: "London"
  },
  */{
        name: "United States",
        flag:
            "https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg",
        number: "+1",
        value: "US",
        timezones: [
            "America/New_York",
            "America/Detroit",
            "America/Kentucky/Louisville",
            "America/Kentucky/Monticello",
            "America/Indiana/Indianapolis",
            "America/Indiana/Vincennes",
            "America/Indiana/Winamac",
            "America/Indiana/Marengo",
            "America/Indiana/Petersburg",
            "America/Indiana/Vevay",
            "America/Chicago",
            "America/Indiana/Tell_City",
            "America/Indiana/Knox",
            "America/Menominee",
            "America/North_Dakota/Center",
            "America/North_Dakota/New_Salem",
            "America/North_Dakota/Beulah",
            "America/Denver",
            "America/Boise",
            "America/Phoenix",
            "America/Los_Angeles",
            "America/Anchorage",
            "America/Juneau",
            "America/Sitka",
            "America/Metlakatla",
            "America/Yakutat",
            "America/Nome",
            "America/Adak",
            "Pacific/Honolulu"
        ],
        position: {lat: 38, lng: -97},
        latlng: [38, -97],
        capital: "Washington D.C."
    },
    /*
  {
    name: "United States Minor Outlying Islands",
    flag:
      "https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg",
    number: "+1581",
    value: "UM",
    timezones: ["Pacific/Johnston", "Pacific/Midway", "Pacific/Wake"],
    position: { lat: 19.2911437, lng: 166.618332 },
    latlng: [19.2911437, 166.618332],
    capital: null
  },
  {
    name: "Uruguay",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Uruguay.svg",
    number: "+598",
    value: "UY",
    timezones: ["America/Montevideo"],
    position: { lat: -33, lng: -56 },
    latlng: [-33, -56],
    capital: "Montevideo"
  },
  {
    name: "Uzbekistan",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Uzbekistan.svg",
    number: "+998",
    value: "UZ",
    timezones: ["Asia/Samarkand", "Asia/Tashkent"],
    position: { lat: 41, lng: 64 },
    latlng: [41, 64],
    capital: "Tashkent"
  },
  {
    name: "Vanuatu",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Vanuatu.svg",
    number: "+678",
    value: "VU",
    timezones: ["Pacific/Efate"],
    position: { lat: -16, lng: 167 },
    latlng: [-16, 167],
    capital: "Port Vila"
  },
  */{
        name: "Viet Nam",
        flag:
            "https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Vietnam.svg",
        number: "+84",
        value: "VN",
        timezones: ["Asia/Ho_Chi_Minh"],
        position: {lat: 16.16666666, lng: 107.83333333},
        latlng: [16.16666666, 107.83333333],
        capital: "Hanoi"
    },
    /*
  {
    name: "Wallis and Futuna",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/d/d2/Flag_of_Wallis_and_Futuna.svg",
    number: "+681",
    value: "WF",
    timezones: ["Pacific/Wallis"],
    position: { lat: -13.3, lng: -176.2 },
    latlng: [-13.3, -176.2],
    capital: "Mata-Utu"
  },
  {
    name: "Yemen",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Yemen.svg",
    number: "+967",
    value: "YE",
    timezones: ["Asia/Aden"],
    position: { lat: 15, lng: 48 },
    latlng: [15, 48],
    capital: "Sana'a"
  },
  {
    name: "Zambia",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/0/06/Flag_of_Zambia.svg",
    number: "+260",
    value: "ZM",
    timezones: ["Africa/Lusaka"],
    position: { lat: -15, lng: 30 },
    latlng: [-15, 30],
    capital: "Lusaka"
  },
  {
    name: "Zimbabwe",
    flag:
      "https://upload.wikimedia.org/wikipedia/commons/6/6a/Flag_of_Zimbabwe.svg",
    number: "+263",
    value: "ZW",
    timezones: ["Africa/Harare"],
    position: { lat: -20, lng: 30 },
    latlng: [-20, 30],
    capital: "Harare"
  }*/
];
