import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        userDoc: {
            _id: "",
            username: "",
            userType: "",
            phoneNumber: "",
            profile:{
                fullName:""
            }
        },
        isDense: true,
        webLayout: true,
        isProperty: false,
        country: "",
        loginPage:false,
        downloadPage:false,
        mobSuccess:false,
        Webview:false
    },
    mutations: {},
    actions: {},
    modules: {}
});
