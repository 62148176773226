<template>
  <v-app
    id="inspire"
    :dark="isDark"
    style="width: 100% !important;display: flex !important; font-family: 'Noto Sans Khmer', roboto"
  >
    <Toolbar
      v-if="!isLogin && !isDownload && !mobSuccess && !isWebview"
    ></Toolbar>
    <v-flex
      xs12
      :style="
        isDownload || isWebview
          ? ''
          : 'min-height: 1200px !important; margin-top: 56px'
      "
      v-if="!isLogin && !mobSuccess"
    >
      <v-fade-transition mode="out-in">
        <router-view></router-view>
      </v-fade-transition>
    </v-flex>
    <v-flex xs12 v-if="isLogin || mobSuccess">
      <v-fade-transition mode="out-in">
        <router-view></router-view>
      </v-fade-transition>
    </v-flex>
    <!--<v-footer app class="white&#45;&#45;text footerColor" v-if="!!userDoc._id">
			<span>&nbsp; buyhousing@gmail.com</span>
			<v-spacer/>
			<span>&copy; 2020</span>
		</v-footer>-->

    <v-footer
      dark
      padless
      v-if="!isLogin && !isDownload && !mobSuccess && !isWebview"
      class="bFooterColor1"
    >
      <v-container class="second-foot-color">
        <v-row
          class="white--text text-center"
          style="margin-left: 0px !important;margin-right: 0px !important;padding-top: 20px !important;padding-bottom: 20px !important;"
          justify="center"
          no-gutters
        >
          <v-col cols="12" md="4" sm="4" class="main-card">
            <h3 class="subtitle">
              DOWNLOAD
              <v-divider class="divider wd-55" />
            </h3>
            <ul>
              <li>
                <a
                  href="/download"
                  target="_blank"
                  rel="noopener noreferrer"
                  class=" font-8 unerlinenone"
                  >AppStore (iOS)
                </a>
              </li>
              <li>
                <a
                  href="/download"
                  target="_blank"
                  rel="noopener noreferrer"
                  class=" font-8 unerlinenone"
                  >GooglePlay (Android)
                </a>
              </li>
            </ul>

            <h3 class="subtitle mt-3">
              CONTACT US
              <v-divider class="divider wd-6" />
            </h3>
            <div class="font-8">
              #2041D Steet 2011 ,Sangkat Kok Kleang, Sen Sok, Phnom Penh<br />
              Tel: (+855) 090262715<br />
              Tel: (+855) 010262715<br />
              FACEBOOK: fb.com/bayonapp
            </div>
          </v-col>
          <v-col cols="12" md="4" sm="4" class="main-card">
            <h3 class="subtitle">
              PROPERTY BY LOCATIONS
              <v-divider class="divider wd-12" />
            </h3>
            <div class="font-8">
              <ul class=" footer-contry-list">
                <li
                  v-for="(d, index) in propertyGroupByLocation.slice(0, 9)"
                  :key="index"
                >
                  <span
                    class="propertyByLocation"
                    @click="
                      goToFilter('sell-my-house-fast', { locationId: d._id })
                    "
                  >
                    {{ d.locationDoc && d.locationDoc.name }} (<span
                      class="num-color"
                      >{{ d.number }}</span
                    >)
                  </span>
                </li>
              </ul>
            </div>
          </v-col>
          <v-col cols="12" md="4" sm="4" class="main-card">
            <h3 class="subtitle">
              ABOUT US
              <v-divider class="divider wd-5" />
            </h3>
            <div class="font-8">
              BAYON APP is a digital real estate investment <br />
              platform develop for buyer, seller, agents, and <br />
              property owner to promote properties: Land, <br />
              house, condo, apartment, warehouse, office space, <br />
              etc…
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-card
        flat
        tile
        class="bFooterColor1 white--text text-center"
        :style="
          !$vuetify.breakpoint.mobile
            ? 'padding-left: 250px;padding-right: 250px;text-align: center !important;width:100%'
            : 'text-align: center !important;width:100%'
        "
      >
        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} —
          <strong>BAYONAPP DIGITAL REALESTATE MARKETPLACE</strong>
        </v-card-text>

        <v-card-text>
          <v-btn
            key="mdi-facebook"
            class="mx-4 white--text"
            icon
            :href="(config && config.facebook) || ''"
            target="_blank"
            rel="noopener noreferrer"
          >
            <v-icon size="24px">mdi-facebook</v-icon>
          </v-btn>
          <v-btn
            key="mdi-twitter"
            class="mx-4 white--text"
            icon
            :href="(config && config.twitter) || ''"
            target="_blank"
            rel="noopener noreferrer"
          >
            <v-icon size="24px">mdi-twitter</v-icon>
          </v-btn>
          <v-btn
            key="mdi-linkedin"
            class="mx-4 white--text"
            icon
            :href="(config && config.linkIn) || ''"
            target="_blank"
            rel="noopener noreferrer"
          >
            <v-icon size="24px">mdi-linkedin</v-icon>
          </v-btn>
          <v-btn
            key="mdi-instagram"
            class="mx-4 white--text"
            icon
            :href="(config && config.instagram) || ''"
            target="_blank"
            rel="noopener noreferrer"
          >
            <v-icon size="24px">mdi-instagram</v-icon>
          </v-btn>
        </v-card-text>

        <v-card-text
          class="white--text pt-0"
          style="text-align: center !important;"
        >
          <em
            >Please read our
            <a
              href="/webview-termofuse"
              target="_blank"
              ref="noreferrer noopener"
              >terms and policies</a
            ></em
          >
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text
          class="white--text pt-0"
          style="text-align: center !important;margin-top: 10px;"
        >
          <div>
            <span style="font-size: 20px;font-weight: bold;padding-right: 20px;"
              >We Accept</span
            ><img
              src="@/assets/a-3-card.png"
              height="40"
              width="273"
              alt="payment methods we accept"
            />
          </div>
        </v-card-text>
        <br /><br />
      </v-card>
    </v-footer>
  </v-app>
</template>
<script>
import Toolbar from "./views/layout/Toolbar.vue";
import { provider } from "@/service/provider";
import axios from "axios";
import MainMixin from "./mixins/mainMixin";

export default {
  name: "App",
  components: {
    Toolbar,
  },
  mixins: [MainMixin],
  data: () => ({
    drawer: true,
    miniVariant: false,
    loading: false,
    isDark: true,
    videoLink: "",
    videoEmbedCode: "",
    windowSize: {
      x: 0,
      y: 0,
    },
    countryIdLocation: "KH",
    propertyGroupByLocation: [],
  }),
  mounted() {
    this.$jQuery("body").off();

    if (localStorage && localStorage.id) {
      this.$store.state.userDoc._id = localStorage.id;
    }
    if (localStorage && localStorage.username) {
      this.$store.state.userDoc.username = localStorage.username;
    }

    if (localStorage && localStorage.phoneNumber) {
      this.$store.state.userDoc.phoneNumber = localStorage.phoneNumber;
    }
    if (localStorage && localStorage.userType) {
      this.$store.state.userDoc.userType = localStorage.userType;
    }

    if (localStorage && localStorage.country) {
      this.$store.state.country = localStorage.country;
    }
    if (localStorage && localStorage.url) {
      this.$store.state.userDoc.url = localStorage.url;
    }
    if (localStorage && localStorage.fullName) {
      this.$store.state.userDoc.profile.fullName = localStorage.fullName;
    }

    this.onResize();
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight;
    });
  },
  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    fetchPropertyGroupByLocation() {
      let vm = this;
      // console.log(localStorage.country + " local storeage");
      vm.countryIdLocation = localStorage.country;
      let url =
        provider.baseURL +
        `/property/groupByLocation?countryId=${vm.countryIdLocation}`;
      return new Promise((resolve, reject) => {
        axios.get(url, { headers: { token: provider.token } }).then(
          (res) => {
            if (res.data.code === 201) {
              resolve(res.data.data);
              let sortedArray = res.data.data.sort(
                (a, b) => b.number - a.number
              );
              vm.propertyGroupByLocation = sortedArray || [];
            }
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    goToFilter(link, query) {
      let vm = this;
      if (vm.$router.history.current.path !== link) {
        // console.log("change params "+ query.locationId)
        this.$router
          .replace({
            name: link,
            query: query,
          })
          .catch(() => {});
      }
    },
  },
  watch: {
    videoLink(val) {
      let vm = this;
      vm.videoEmbedCode = "";
      if (val && val.split("youtu.be").length === 2) {
        vm.videoEmbedCode = val.replace("youtu.be", "youtube.com/embed");
      } else if (val && val.split("/watch?v=").length > 0) {
        vm.videoEmbedCode =
          "https://www.youtube.com/embed/" +
          val
            .split("/watch?v=")[1]
            .split("&list=")[0]
            .split("&t=")[0];
      }
    },
  },
  beforeCreate() {
    let vm = this;
    setTimeout(function() {
      if (localStorage.country && localStorage.country !== "") {
        vm.$jQuery.get("https://www.cloudflare.com/cdn-cgi/trace", function(
          data
        ) {
          if (data) {
            vm.$store.state.country = data.split("loc=")[1].substr(0, 2);
            localStorage.country =
              vm.$store.state.country || localStorage.country;
            // console.log("before create " + localStorage.country)
            vm.fetchPropertyGroupByLocation();
          }
        });
      }
    }, 1000);
  },
  created() {
    let vm = this;
    vm.$vuetify.goTo(0);
    /*window.gapi.load("auth2",function (){
			window.gapi.auth2.init()
		})*/
    vm.videoLink = "https://www.youtube.com/watch?v=73rd6olCR9A";

    let url = provider.baseURL + "/web_config/fetch";
    return new Promise((resolve, reject) => {
      axios.get(url, { headers: { token: provider.token } }).then(
        (res) => {
          if (res.data.data) {
            resolve(res.data.data);
            vm.config = res.data.data;
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  },
  computed: {
    userDoc() {
      return this.$store.state.userDoc;
    },
    isLogin() {
      return this.$store.state.loginPage;
    },
    isDownload() {
      return this.$store.state.downloadPage;
    },
    mobSuccess() {
      return this.$store.state.mobSuccess;
    },
    isWebview() {
      return this.$store.state.webview;
    },
  },
};
</script>

<style scoped>
.footerColor {
  background-image: linear-gradient(
    rgba(115, 43, 123, 0.9),
    rgba(115, 43, 123, 0.9)
  ) !important;
}
.bFooterColor1 {
  background-image: linear-gradient(
    rgba(115, 43, 123, 0.9),
    rgba(115, 43, 123, 0.9)
  ) !important;
}
.propertyByLocation {
  cursor: pointer;
}
.propertyByLocation:hover {
  text-decoration: underline;
}
</style>
