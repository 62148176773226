import Vue from "vue";
import VueRouter from "vue-router";
import {web} from "./web";
import { webview } from "./webview";
import Store from "@/store/index"

Vue.use(VueRouter);
const routes = [
    {
        path: "/",
        name: "web-home",
        meta: {
            breadCrumb: "Web Home",
            roles: ""
        },
        component: () =>
            import(/*webpackChunkName: "web_home"*/ "@/views/web_home.vue")
    },
    {
        path: "/login",
        name: "Login",
        meta: {},
        component: () =>
            import(/*webpackChunkName: "login"*/ "@/views/user/login.vue")
    },
    {
        path: "*",
        name: "notFound",
        meta: {},
        component: () =>
            import(/*webpackChunkName: "notFound"*/ "@/views/notFound.vue")
    },
    ...web,
    ...webview
];

const router = new VueRouter({
    // mode: process.env.CORDOVA_PLATFORM ? "hash" : "history",
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
router.beforeEach((to, from, next) => {

    Store.state.loginPage=(to.path === "/login" || to.path === "/loginWithUser");
    Store.state.downloadPage=(to.path === "/download");
    Store.state.mobSuccess=(to.path === "/subscribe-success-mobile");
    Store.state.webview= (to.path === "/webview-termofuse" || to.name === "webview reports"|| to.name === "webview general reports");
    if (to.path === "/add-property" && !localStorage.id) {
        next("/login");
    } else {
        next();
    }
    /*switch (to.meta.roles) {
        case "Setting":
            localStorage.id ? next() : next("/login");
            break;
        case "":
            localStorage.id  ? next() : next("/login");
            break;
        default:
            next();
            break;
    }*/


});
