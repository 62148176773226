<template>
  <div>
    <v-app-bar app color="purple" flat dark>
      <v-app-bar-nav-icon
        @click="drawer = true"
        class="d-flex d-md-none"
      ></v-app-bar-nav-icon>
      <span>
        <h2
          v-if="!$vuetify.breakpoint.mobile"
          style="padding-left: 20px; word-break: unset"
        >
          BAYON<span style="color: transparent">_</span>APP
        </h2>
        <h3
          v-if="$vuetify.breakpoint.mobile"
          style="padding-left: 20px; word-break: unset"
        >
          BAYON<span style="color: transparent">_</span>APP
        </h3>
      </span>

      <v-tabs v-model="active" align-with-title class="d-none d-md-flex">
        <v-tabs-slider color="white"></v-tabs-slider>
        <v-tab to="/">{{ $t("home") }}</v-tab>
        <!-- ============== For Sale ============== -->
        <!-- <v-tab> -->
          <v-menu offset-y bottom rounded="b-lg" transition="scale-transition" key="sale">
            <template v-slot:activator="{ on, attrs }">
              <v-tab v-bind="attrs" v-on="on">
                {{ $t("forSale") }} 
                <v-icon>
                  mdi-menu-down
                </v-icon>
              </v-tab>
            </template>
            <v-list class="bg-main-color">
              <v-list-item
                v-for="(type, index) in propertiesType"
                :key="index"
                link
                class="list-item"
                :to="{
                  name: 'sell-my-house-fast',
                  query: { type: type.link, groupType: 'sale' },
                }"
              >
                <v-list-item-title
                  class="sub-link-color"
                  style="font-size: 0.9rem !important"
                  >{{ $t(type.link) }}</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        <!-- </v-tab> -->
        <!-- ============= For Rent ============== -->
        <!-- <v-tab> -->
          <v-menu offset-y bottom rounded="b-lg" transition="scale-transition" key="rent">
            <template v-slot:activator="{ on, attrs }">
              <v-tab v-bind="attrs" v-on="on">
                {{ $t("forRent") }} 
                <v-icon>
                  mdi-menu-down
                </v-icon>
              </v-tab>
            </template>
            <v-list class="bg-main-color">
              <v-list-item
                v-for="(type, index) in propertiesType"
                :key="index"
                link
                class="list-item"
                :to="{
                  name: 'sell-my-house-fast',
                  query: { type: type.link, groupType: 'rent' },
                }"
              >
                <v-list-item-title
                  class="sub-link-color"
                  style="font-size: 0.9rem !important"
                  >{{ $t(type.link) }}</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        <!-- </v-tab> -->
        <v-tab to="/agents">{{ $t("agents") }}</v-tab>
        <v-tab to="/companies">{{ $t("companies") }}</v-tab>
        <v-tab to="/news">{{ $t("news") }}</v-tab>
        <v-tab to="/download">{{ $t("download") }}</v-tab>
      </v-tabs>
      <v-spacer></v-spacer>
      <template
        rounded
        offset-y
        v-if="!!userDoc._id && !$vuetify.breakpoint.mobile"
      >
        <v-btn to="/add-property" color="success">
          <span> {{ $t("addProperty") }}</span>
        </v-btn>
      </template>
      <v-tooltip left color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            @click="changeLanguage"
            v-bind="attrs"
            v-on="on"
          >
            <v-avatar size="36px">
              <img
                v-if="$i18n.locale === 'en'"
                alt="cambodiaLogo"
                style="background-size: contain"
                src="../../assets/Flag_of_Cambodia.svg"
              />
              <img
                v-else
                alt="USlogo"
                style="background-size: contain"
                src="../../assets/Flag_of_the_United_States.svg"
              />
            </v-avatar>
          </v-btn>
        </template>
        <span>Change Langauge</span>
      </v-tooltip>

      <v-menu
        rounded
        offset-y
        v-if="!!userDoc._id && !$vuetify.breakpoint.mobile"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            @click="getExpiredDate(userDoc._id)"
          >
            <v-avatar size="36px">
              <img
                v-if="userDoc.url === '' || userDoc.url === undefined"
                alt="user"
                style="background-size: contain"
                src="../../assets/user.png"
              />
              <img
                alt="user"
                v-else
                style="background-size: contain"
                :src="userDoc.url"
              />
            </v-avatar>
          </v-btn>
        </template>

        <div
          style="
            min-width: 300px;
            padding-top: 20px;
            text-align: center;
            background-color: white;
          "
        >
          

          <v-avatar size="100px">
            <img
              v-if="
                (userDoc && userDoc.url === '') || userDoc.url === undefined
              "
              alt="user"
              style="background-size: contain"
              src="../../assets/user.png"
            />
            <img
              v-else
              alt="user"
              style="background-size: contain"
              :src="userDoc.url"
            />
          </v-avatar>
          <br />
          {{ $store.state.userDoc.profile.fullName }}<br />
          <div style="color: teal; font-weight: bold">
            {{ $store.state.userDoc.userType }}
          </div>
          <div
            :style="currentDate > expiredDate ? 'color: red' : 'color: blue'"
            v-if="expiredDate && $store.state.userDoc.userType != 'Member'"
          >
            {{ $t("expired") }} :
            {{ expiredDate | momentFormatM }}
          </div>

          <v-btn
            v-if="currentDate > expiredDate"
            style="margin-top: 10px"
            color="green"
            outlined
            @click="
              goToPayment('add-payment-noparam', {
                planDoc: currentPlan,
                userId: userDoc._id,
              })
            "
            small
          >
            Renew
          </v-btn>
          <v-btn
            style="margin-top: 10px; margin-left: 10px"
            
            color="red"
            outlined
            small
            @click="dialog = true"
          >
            Upgrade
          </v-btn>
        </div>
        <v-list>
          <!-- <v-list-item
            link
            key="my-property"
            :to="{
              name: 'my-property',
              params: { userId: $store.state.userDoc._id },
            }"
          >
            <v-list-item-title>
              <v-icon>mdi-atlassian</v-icon>
              {{ $t("myProperty") }}
            </v-list-item-title>
          </v-list-item> -->
          <v-list-item
            link
            key="profile"
            :to="{
              name: 'my-profile',
              params: { userId: $store.state.userDoc._id },
            }"
          >
            <v-list-item-title>
              <v-icon>mdi-account</v-icon>
              {{ $t("myProfile") }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item link>
            <v-list-item-title @click="logout">
              <v-icon>mdi-logout</v-icon>
              {{ $t("logout") }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        color="success"
        dark
        to="/login"
        v-if="!userDoc._id && !$vuetify.breakpoint.mobile"
      >
        {{ $t("freePost") }}
      </v-btn>
    </v-app-bar>
    <!-- Add a navigation bar -->
    <v-navigation-drawer v-model="drawer" fixed temporary class="purple" dark>
      <template rounded offset-y v-if="!!userDoc._id">
        <div style="max-width: 256px; text-align: center">
          <v-btn
            style="
              float: right;
              position: absolute;
              margin-left: 140px;
              z-index: 2000;
            "
            icon
            @click.stop="drawer = !drawer"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-avatar size="100px" style="margin-top: 10px">
            <img
              v-if="
                (userDoc && userDoc.url === '') || userDoc.url === undefined
              "
              alt="user"
              style="background-size: contain"
              src="../../assets/user.png"
            />
            <img
              v-else
              alt="user"
              style="background-size: contain"
              :src="userDoc.url"
            />
          </v-avatar>
          <br />
          <span style="color: white">{{ $store.state.userDoc.profile.fullName }}</span><br />
          <div style="color: white; font-weight: bold">
            {{ $store.state.userDoc.userType }}
          </div>
          <div
            :style="currentDate > expiredDate ? 'color: red' : 'color: blue'"
            v-if="expiredDate  && $store.state.userDoc.userType != 'Member'"
          >
            {{ $t("expired") }} :
            {{ expiredDate | momentFormatM }}
          </div>

          <v-btn
            v-if="currentDate > expiredDate"
            style="margin-top: 10px"
            color="white"
            outlined
            @click="
              goToPayment('add-payment-noparam', {
                planDoc: currentPlan,
                userId: userDoc._id,
              })
            "
            small
          >
            Renew
          </v-btn>
          <v-btn
            style="margin-top: 10px; margin-left: 10px"
            color="white"
            outlined
            small
            @click="dialog = true"
          >
            Upgrade
          </v-btn>
        </div>

        <v-list>
          <v-list-item
            link
            key="add-property"
            to="/add-property"
          >
            <v-list-item-title>
              <v-icon>mdi-plus</v-icon>
              {{ $t("addProperty") }}
            </v-list-item-title>
          </v-list-item>
          <!-- <v-list-item
            link
            key="my-property"
            :to="{
              name: 'my-property',
              params: { userId: $store.state.userDoc._id },
            }"
          >
            <v-list-item-title>
              <v-icon>mdi-atlassian</v-icon>
              {{ $t("myProperty") }}
            </v-list-item-title>
          </v-list-item> -->
          <v-list-item
            link
            key="profile"
            :to="{
              name: 'my-profile',
              params: { userId: $store.state.userDoc._id },
            }"
          >
            <v-list-item-title>
              <v-icon>mdi-account</v-icon>
              {{ $t("myProfile") }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
      <template v-else>
        <div style="max-width: 256px; text-align: center">
          <v-btn
            style="
              float: right;
              position: absolute;
              margin-left: 140px;
              z-index: 2000;
            "
            icon
            @click.stop="drawer = !drawer"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-avatar size="100px" style="margin-top: 10px">
            <img
              v-if="
                (userDoc && userDoc.url === '') || userDoc.url === undefined
              "
              alt="user"
              style="background-size: contain"
              src="../../assets/user.png"
            />
          </v-avatar>
          <br />
          <h4 class="whiteColor">Welcome to Bayon App!</h4>
        </div>
        <br />
      </template>

      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item link class="list-item" to="/">
            <v-list-item-title>
              <v-icon>mdi-home</v-icon>
              {{ $t("home") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-group :value="false">
            <template v-slot:activator>
              <v-list-item-title
                ><v-icon style="margin-right: 7px;">mdi-home-city</v-icon>{{
                  $t("forSale")
                }}</v-list-item-title
              >
            </template>
            <v-list>
              <v-list-item
                v-for="(type, index) in propertiesType"
                :key="index"
                link
                class="list-item"
                style="margin-left: 32px"
                :to="{
                  name: 'sell-my-house-fast',
                  query: { type: type.link, groupType: 'sale' },
                }"
              >
                <v-list-item-title
                  class="sub-link-color"
                  style="font-size: 0.9rem !important"
                  >{{ $t(type.link) }}</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-list-group>

          <v-list-group :value="false">
            <template v-slot:activator>
              <v-list-item-title
                ><v-icon style="margin-right: 7px;">mdi-home-city-outline</v-icon>{{
                  $t("forRent")
                }}</v-list-item-title
              >
            </template>
            <v-list>
              <v-list-item
                v-for="(type, index) in propertiesType"
                :key="index"
                link
                class="list-item"
                style="margin-left: 32px"
                :to="{
                  name: 'sell-my-house-fast',
                  query: { type: type.link, groupType: 'rent' },
                }"
              >
                <v-list-item-title
                  class="sub-link-color"
                  style="font-size: 0.9rem !important"
                  >{{ $t(type.link) }}</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-list-group>
          <v-list-item link class="list-item" to="/agents">
            <v-list-item-title>
              <v-icon>mdi-account</v-icon>
              {{ $t("agents") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item link class="list-item" to="/companies">
            <v-list-item-title>
              <v-icon>mdi-domain</v-icon>
              {{ $t("companies") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item link class="list-item" to="/news">
            <v-list-item-title>
              <v-icon>mdi-bell</v-icon>
              {{ $t("news") }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item link class="list-item" to="/download">
            <v-list-item-title>
              <v-icon>mdi-download</v-icon>
              {{ $t("download") }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-if="!!userDoc._id" v-slot:append>
        <div class="pa-2" @click="logout">
          <v-btn block class="loginBtn"> <v-icon>mdi-logout</v-icon>{{ $t("logout") }} </v-btn>
        </div>
      </template>
      <template v-else v-slot:append>
        <div class="pa-2">
          <v-btn block to="/login" class="loginBtn">
            <v-icon>mdi-login</v-icon>{{ $t("login") }}
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <!-- Navigation bar ends -->
    <v-dialog v-model="dialog" persistent max-width="80%">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("subscription") }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="3"
              sm="3"
              v-for="(d, i) in planList"
              :key="i"
              style="border-right: 1px solid green"
            >
              <div
                style="
                  text-align: center;
                  font-weight: bold;
                  font-size: 30px;
                  padding-bottom: 10px;
                "
              >
                {{ d.name == "Member" ? "Free " : "" }}{{ d.name }}
              </div>
              <v-divider></v-divider>
              <div
                style="
                  text-align: center;
                  font-size: 20px;
                  margin-top: 10px;
                  padding-bottom: 30px;
                  font-weight: bold;
                  color: green;
                "
              >
                ${{ d.price }}/{{ $t("month") }}
              </div>
              <p>{{ $t("numProperty") }} : {{ d.numProperty }}</p>
              <p>{{ $t("numRenew") }} : {{ d.numRenew }}</p>
              <p>{{ $t("numAgent") }} : {{ d.numAgent }}</p>
              <p>{{ d.advertiseNote }}</p>
              <p
                style="text-align: center"
                v-if="
                  d.name !== 'Member' &&
                  userDoc.userType !== d.name &&
                  ((currentPlan && currentPlan.price < d.price) || false)
                "
              >
                <v-btn
                  @click="
                    goToUpgrade(d.name, { planDoc: d, userId: userDoc._id })
                  "
                  depressed
                  color="primary"
                >
                  {{ $t("choosePlan") }}
                </v-btn>
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" @click="dialog = false">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { provider } from "@/service/provider";
import axios from "axios";
import moment from "moment";

export default {
  props: {},
  watch: {
    selectedPlan(val) {
      console.log(val);
    },
  },
  data() {
    return {
      dialog: false,
      toolbarColor: "#84B628;",
      drawer: false,
      planList: [],
      selectedPlan: "",
      expiredDate: "",
      currentPlan: {},
      propertiesType: [
        {
          title: "Land",
          link: "land",
        },
        {
          title: "House",
          link: "house",
        },
        {
          title: "Room",
          link: "room",
        },
        {
          title: "Condo",
          link: "condo",
        },
        {
          title: "Apartment",
          link: "apartment",
        },
        {
          title: "FarmLand",
          link: "farmLand",
        },
        {
          title: "RoomForRent",
          link: "roomForRent",
        },
        {
          title: "TwinVilla",
          link: "twinVilla",
        },
        {
          title: "QueenVilla",
          link: "queenVilla",
        },
        {
          title: "ShopHouse",
          link: "shopHouse",
        },
        {
          title: "Office",
          link: "office",
        },
        {
          title: "Warehouse",
          link: "warehouse",
        },
      ],
      active: "home",
      group: null,
    };
  },
  methods: {
    logout() {
      let vm = this;
      this.$store.state.userDoc._id = "";
      this.$store.state.userDoc.username = "";
      localStorage.clear();
      vm.$message({
        message: this.$t('logoutSuccess'),
        showClose: true,
        type: "success",
      });      
      if (this.$router.history.current.name !== "web-home") {
        this.$router.push({ name: "web-home" });
      }
    },
    goToPayment(link, params) {
      let vm = this;
      vm.dialog = false;
      if (vm.$router.history.current.path !== link) {
        this.$router.push({
          name: link,
          params: params,
        });
      }
    },
    goToUpgrade(type, params) {
      let vm = this;
      vm.dialog = false;
      let link = type === "Agent" ? "upgrade-agent" : "upgrade-agency";
      if (vm.$router.history.current.path !== link) {
        this.$router.push({
          name: link,
          params: params,
        });
      }
    },
    getExpiredDate(userId) {
      let vm = this;
      let url = provider.baseURL + `/userSubscription/byUserId`;
      return new Promise((resolve, reject) => {
        axios
          .get(url, {
            params: { userId: userId },
            headers: { token: provider.token },
          })
          .then(
            (res) => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.expiredDate = res.data.data.endDate;
              }
            },
            (error) => {
              reject(error);
            }
          );
      });
    },
    changeLanguage() {
      this.$i18n.locale = this.$i18n.locale === "km" ? "en" : "km";
      this.$vuetify.lang.current = this.$i18n.locale;
    },
    clickSubscribe() {
      let vm = this;
      vm.drawer = !vm.drawer;
    },
    fetchPlanType() {
      let vm = this;
      let url = provider.baseURL + `/plan/fetch`;
      return new Promise((resolve, reject) => {
        axios.get(url, { headers: { token: provider.token } }).then(
          (res) => {
            if (res.data.code === 201) {
              resolve(res.data.data);
              vm.planList = (res.data.data && res.data.data.content) || [];
              this.currentPlan = this.planList.find(
                (o) => o.name === this.$store.state.userDoc.userType
              );
            }
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
  },
  computed: {
    isProperty() {
      return this.$store.state.isProperty;
    },
    userDoc() {
      this.fetchPlanType();
      return this.$store.state.userDoc;
    },
    currentDate() {
      return moment().format("YYYY-MM-DD");
    },
  },
  created() {
    this.fetchPlanType();
  },
};
</script>
<style scoped>
.toolbarColor {
  background-image: linear-gradient(
    rgba(115, 43, 123, 0.9),
    rgba(115, 43, 123, 0.9)
  ) !important;
}
.loginBtn {
  background-color: green !important;
}
.whiteColor {
  color: white !important;
}
</style>
